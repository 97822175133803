<template>
  <div v-loading="$store.state.tableData_loading_my_drawings" class="con-right">
    <div class="con-right-box">
      <div style="display: flex;height:55px;min-width: 960px;justify-content:space-between;margin-right:60px" v-if="!is_search_file">
        <div
          style="
            margin-left: 5px;
            margin-bottom: 20px;
            min-width: 680px;
            overflow: hidden;
          "
        >
          <el-button
            plain
            icon="share-icon-default"
            class="my_dwg_top_btn share-btn-default"
            @click="batch_share_dwg"
            >发送图纸</el-button
          >
          <el-button
            plain
            icon="el-icon-download"
            class="my_dwg_top_btn"
            @click="DOWN_FILES"
            :disabled="menus_down_action"
            >下载</el-button
          >

          <el-button
            plain
            icon="el-icon-edit"
            class="my_dwg_top_btn"
            @click="UPDATE_NAME"
            :disabled="menus_update_action"
            >重命名</el-button
          >
          <!-- <el-button
            plain
            icon="el-icon-star-off"
            class="my_dwg_top_btn"
            @click="SHOUCANG_DWGS"
            :disabled="menus_sc_action"
            >收藏</el-button
          > -->
          <el-button
            plain
            icon="el-icon-delete"
            class="my_dwg_top_btn"
            @click="DELETE_LISTS"
            :disabled="menus_del_action"
            >删除</el-button
          >

          <el-button
            plain
            icon="el-icon-delete"
            class="my_dwg_top_btn"
            @click="PASTE_FILE"
            v-if="$store.state.menus_paste_action"
            >粘贴</el-button
          >
        </div>
        
        <div class="serach_file_box_sm">
          <el-input placeholder="搜索图纸文件" v-model="search_file_input" class="input-with-select" @click.native="click_serach_input">
            <el-button slot="append" @click.stop="click_search_btn">搜索</el-button>
          </el-input>
        </div>

      </div>

      <div class="serach_file_box" v-if="is_search_file">
        <el-button
          plain
          icon="el-icon-arrow-left"
          class="my_dwg_top_btn"
          style="margin-right:20px;height:30px"
          @click="is_search_file = false"
          >返回</el-button
        >
        <div slot="" icon="el-icon-search" style="color:blue;background:rgba(0,0,0,0)"></div>

        <el-input placeholder="搜索图纸文件" v-model="search_file_input" class="input-with-select" ref="searchfileinput" @input="changeSeachInput">
          <el-button slot="append">搜索</el-button>
        </el-input>
      </div>
      

      <div class="files-table">
        <div class="cloud_file_header lately_file_header">
          <div class="cloud_file_header_checked_box">
            <el-checkbox
              style="height: 19.69px"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              v-if="
                $store.state.tableData_my_drawings.length != 0 &&
                is_show_checkedAll && !is_search_file
              "
              >{{ checkAllText }}</el-checkbox
            >
            <el-breadcrumb
              separator=">"
              style="
                font-size: 16px;
                line-height: unset;
                width: calc(100% - 370px);
                white-space: nowrap;
                overflow: hidden;
                margin-left: 10px;
              "
              v-if="!is_show_checkedAll && !is_search_file"
              v-show="$store.state.cur_cloud_paths.length < 4"
            >
              <el-breadcrumb-item
                v-for="(item, i) in $store.state.cur_cloud_paths"
                :key="i"
                @click.native="go_to_cloud_path(item.path, i)"
                >{{ item.text }}
              </el-breadcrumb-item>
            </el-breadcrumb>

            <el-breadcrumb
              separator=">"
              style="
                font-size: 16px;
                line-height: unset;
                width: calc(100% - 370px);
                white-space: nowrap;
                overflow: hidden;
                margin-left: 10px;
              "
              v-if="!is_show_checkedAll && !is_search_file"
              v-show="$store.state.cur_cloud_paths.length >= 4"
            >

              <el-breadcrumb-item
                v-for="(item, i) in $store.state.cur_cloud_paths"
                :key="i"
                v-show="i == 0 || i == 1 || $store.state.cur_cloud_paths.length-1 - i == 0 || $store.state.cur_cloud_paths.length-1 - i == 1"
                @click.native="go_to_cloud_path(item.path, i)"
                >{{ i == 1 ? '...' : item.text }}
              </el-breadcrumb-item>
              
              </el-breadcrumb>
            <div v-if="is_search_file" style="color:#4383f0;font-size:16px;margin-left: 10px;">CAD云盘</div>
            <div class="line-cloud"></div>
          </div>

          <div class="cloud_right">
            <div
              class="cloud_right_add"
              id="batch_share_dwg"
              style="color: rgb(118, 124, 133)"
              @click="batch_share_dwg"
              v-if="!is_search_file"
            >
              <img src="@/assets/images/cloud/dwg_share.png" alt="" />
              <span>批量发送</span>
            </div>
            <div
              class="cloud_right_add"
              id="create_newdir_btn"
              style="margin-left: 20px; color: rgb(118, 124, 133)"
              @click="add_dir_item"
              v-if="!is_search_file"
            >
              <img src="@/assets/images/cloud/dir_add1.png" alt="" />
              <span>新建文件夹</span>
            </div>
            <div
              class="cloud_right_add"
              id="create_newdwg_btn1"
              style="margin-left: 20px; color: rgb(118, 124, 133)"
              @click="show_create_box_btn"
              v-if="!is_search_file"
            >
              <img src="@/assets/images/cloud/dwg_add.png" alt="" />
              <span>新建图纸</span>
            </div>
            <div class="cloud_right_up" v-if="!is_search_file">
              <el-dropdown trigger="click" @command="upload_item_btn">
                <div class="team_cloud_right_up" id="team_upload_btn">
                  <img
                    src="@/assets/images/cloud/file_shangchuan1.png"
                    alt=""
                  />
                  <span>上传</span>
                </div>
                <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-160">
                  <el-dropdown-item
                    :command="1"
                    :type="it.type"
                    v-for="(it, index) in upload_btn_items"
                    :key="index"
                    >{{ it.text }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>

              <!-- 
              <img src="@/assets/images/cloud/file_shangchuan1.png" alt="" />
              <span>上传</span> -->
              <div id="cloud_up_btn_box" style="display: none">
                <div class="header_down_flex">
                  <div class="header_down_item" id="cloud_up_btn_dwg_btn">
                    <!-- <img src="@/assets/images/team/cc-file.png" alt=""> -->
                    <span>上传图纸</span>
                  </div>
                  <div class="header_down_item" id="cloud_up_btn_dir_btn">
                    <!-- <img src="@/assets/images/team/cc-file.png" alt=""> -->
                    <span>上传文件夹</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="cloud_right_add" id="shoucang_right_refresh" @click="refresh_lists_btn_my" v-if="!is_search_file">
              <img src="@/assets/images/cloud/refresh.png" alt="">
              <span>刷新</span>
            </div>
            <div class="cloud_right_add" id="shoucang_right_sort" style="margin-left: 20px;position: relative;">
              <el-dropdown trigger="click" @command="dropdown_sort_btn_my">
                <span class="el-dropdown-link" style="display:flex;align-items: center;">
                  <img src="@/assets/images/cloud/sort.png" alt="">
                  <span>排序:{{$store.state.tableData_my_drawings_sort.text}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                </span>
                <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-160">
                  <el-dropdown-item
                      :command="it"
                      v-for="(it, index) in dropdown_sort_items"
                      :key="index"
                      >{{ it.text  }}</el-dropdown-item
                    >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>

        <div
          id="lately_list_head"
          v-if="$store.state.tableData_my_drawings.length != 0 || is_search_file == true"
        >
          <div class="file-item lately-item file-item-no-hover">
            <div class="file-name file-item-lately" style="width: calc(35% + 22px);">
              <div>名称</div>
            </div>
            <div class="file-update-time list_header_color">修改时间</div>
            <div class="file-type list_header_color">文件类型</div>
            <div class="file-size list_header_color">文件大小</div>
            <div class="file-do caozuo-box"></div>
          </div>
        </div>
        <div
          id="lately_list"
          v-if="$store.state.tableData_my_drawings.length != 0 && !is_search_file"
        >
          <div
            class="file-item lately-item"
            :class="cur_click_item_index == i ? 'file-item-active' : ''"
            v-for="(item, i) in $store.state.tableData_my_drawings"
            :key="i"
            @mouseenter="show_share_btn(i)"
            @mouseleave="hide_share_btn(i)"
            @click="is_show_share_click(i)"
            @contextmenu.prevent="show_right_menus_cloud($event, item, i)"
          >
            <el-checkbox
              @change="handleCheckedMyTableChange(item)"
              v-model="item.checked"
              v-if="!item.input"
              style="margin-left: 8px"
            ></el-checkbox>
            <div
              style="width: 14px; height: 14px; margin-left: 8px"
              v-if="item.input"
            ></div>
            <div class="file-name">
              <img
                :src="imgSrc1"
                @click="open_dwg_my(item)"
                v-if="item.type == 'file'"
              />
              <img
                :src="is_dir"
                @click="open_dwg_my(item)"
                v-if="item.type == 'dir'"
              />
              <img
                :src="item.expired ? imgSrc3 : imgSrc2"
                @click="open_dwg_my(item)"
                v-if="item.type == 'link' || item.type == 'linkfile'"
              />
              <div
                class="file-name-word"
                :title="item.name"
                style="color: rgb(61, 71, 87)"
                @click="open_dwg_my(item)"
                v-if="!item.input"
              >
                {{ item.name }}
              </div>
              <div class="input-dir-name" v-if="item.input">
                <input
                  type="text"
                  ref="addDirInput"
                  style="width: 180px"
                  v-model="add_dir_input"
                  :autofocus="autofocus"
                  @keyup.enter="create_newdir_onSubmit"
                  @keyup.esc="create_newdir_no"
                />
                <div id="create_newdir_yes" @click="create_newdir_yes">
                  <img src="@/assets/images/common/add_file_cha.png" />
                </div>
                <div id="create_newdir_no" @click="create_newdir_no">
                  <img src="@/assets/images/common/add_file_gou.png" />
                </div>
              </div>
            </div>
            <div class="file-update-time">{{ item.mtime1 }}</div>
            <div class="file-type" :title="item.type == 'dir' ? '文件夹' : item.share_from">
              {{ item.type == "dir" ? "文件夹" : item.share_from }}
            </div>
            <div class="file-size">{{ item.sizeMB }}</div>
            <div class="caozuo-box">
              <div class="file-more" v-if="!item.input">
                <el-dropdown trigger="click" @command="dropdown_item_btn_my">
                  <span class="el-dropdown-link">
                    <img src="@/assets/images/cloud/more.png" />
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-dropdown-menu-160"
                    v-if="item.type == 'dir'"
                  >
                    <el-dropdown-item
                      :command="item"
                      :type="it.type"
                      :index="i"
                      v-for="(it, index) in dropdown_items"
                      :divided="index== 5 || index == 8 ? true : false"
                      :key="index"
                      :disabled="item.type == 'dir' && !it.is_dir"
                      >{{ it.text }}</el-dropdown-item
                    >
                  </el-dropdown-menu>

                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-dropdown-menu-160"
                    v-if="item.type == 'file'"
                  >
                    <el-dropdown-item
                      :command="item"
                      :type="it.type"
                      :index="i"
                      v-for="(it, index) in dropdown_items"
                      :divided="index== 5 || index == 8? true : false"
                      :key="index"
                      :disabled="(item.type == 'file' && it.is_share)"
                      >{{ item.is_collect == 'y' && it.text == "收藏" ? '取消收藏' : it.text  }}</el-dropdown-item
                    >
                  </el-dropdown-menu>

                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-dropdown-menu-160"
                    v-if="item.type != 'file' && item.type != 'dir'"
                  >
                    <el-dropdown-item
                      :command="item"
                      :type="it.type"
                      :index="i"
                      :divided="index== 5 || index == 8 ? true : false"
                      v-for="(it, index) in dropdown_items"
                      :key="index"
                      :disabled="it.type == 'pdf' || it.type == 'down'"
                      >
                      {{ item.is_collect == 'y' && it.text == "收藏" ? '取消收藏' : it.text  }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div
                class="file-collection file_collect"
                title="收藏当前图纸"
                @click="
                  collect_cur_dwg_my(
                    i,
                    item
                  )
                "
                v-if="item.is_collect == 'y' && item.type != 'dir'"
              >
                <img
                  :src="item.is_collect == 'n' ? is_collect_0 : is_collect_1"
                />
              </div>
              <div
                class="file-collection file_collect"
                title="收藏当前图纸"
                @click="
                  collect_cur_dwg_my(
                    i,
                    item
                  )
                "
                v-if="item.is_collect == 'n' && item.is_show_share && item.type != 'dir'"
              >
                <img
                  :src="item.is_collect == 'n' ? is_collect_0 : is_collect_1"
                />
              </div>
              <div
                class="file-share"
                title="生成网页链接发送给他人查看"
                v-show="item.is_show_share && item.type != 'dir'"
                @click="send_share_dwg_btn_my(item)"
              >
                发送图纸
              </div>
              <div
                class="file-pdf"
                :class="item.pdf == 1 ? 'file-pdf-active' : ''"
                title="以PDF图框分页浏览图纸" 
                v-show="item.is_show_share && item.type == 'file' && item.file_type == '2d'"
                @click="open_pdf(item)">
                <div class="file-pdf-process" :style="'width:'+ item.process + '%'"></div>
                PDF
              </div>
            </div>
          </div>
        </div>

        <div
          id="search_list"
          v-if="is_search_file"
        >
          <div
            class="file-item lately-item"
            :class="cur_click_item_index == i ? 'file-item-active' : ''"
            v-for="(item, i) in serchFiles"
            :key="i"
            @mouseenter="show_share_btn(i)"
            @mouseleave="hide_share_btn(i)"
            @click="is_show_share_click(i)"
            @contextmenu.prevent="show_right_menus_cloud($event, item, i)"
          >
            <div
              style="width: 14px; height: 14px; margin-left: 8px"
              v-if="item.input"
            ></div>
            <div class="file-name file-name-serach">
              <img
                :src="imgSrc1"
                @click="open_dwg_my(item)"
                v-if="item.type == 'file'"
              />
              <img
                :src="is_dir"
                @click="open_dwg_my(item)"
                v-if="item.type == 'dir'"
              />
              <img
                :src="item.expired ? imgSrc3 : imgSrc2"
                @click="open_dwg_my(item)"
                v-if="item.type == 'link' || item.type == 'linkfile'"
              />
              <div
                class="file-serachname"
                :title="item.name"
                style="color: rgb(61, 71, 87)"
                @click="open_dwg_my(item)"
                v-if="!item.input"
              >
                <span class="file-cloud-name"><a href="#" v-html="item.colorName"></a></span>
                <span class="file-cloud-path">{{ item.cloud_path }}</span>
              </div>
              <div class="input-dir-name" v-if="item.input">
                <input
                  type="text"
                  ref="addDirInput"
                  style="width: 180px"
                  v-model="add_dir_input"
                  :autofocus="autofocus"
                  @keyup.enter="create_newdir_onSubmit"
                  @keyup.esc="create_newdir_no"
                />
                <div id="create_newdir_yes" @click="create_newdir_yes">
                  <img src="@/assets/images/common/add_file_cha.png" />
                </div>
                <div id="create_newdir_no" @click="create_newdir_no">
                  <img src="@/assets/images/common/add_file_gou.png" />
                </div>
              </div>
            </div>
            <div class="file-update-time">{{ item.mtime1 }}</div>
            <div class="file-type">
              {{ item.type == "dir" ? "文件夹" : item.share_from }}
            </div>
            <div class="file-size">{{ item.sizeMB }}</div>
            <div class="caozuo-box">
              <div class="file-more" v-if="!item.input">
                <el-dropdown trigger="click" @command="dropdown_item_btn_my">
                  <span class="el-dropdown-link">
                    <img src="@/assets/images/cloud/more.png" />
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-dropdown-menu-160"
                    v-if="item.type == 'dir'"
                  >
                    <el-dropdown-item
                      :command="item"
                      :type="it.type"
                      :index="i"
                      v-for="(it, index) in search_dropdown_items"
                      :divided="index== 5 || index == 8 ? true : false"
                      :key="index"
                      :disabled="item.type == 'dir' && !it.is_dir"
                      >{{ it.text }}</el-dropdown-item
                    >
                  </el-dropdown-menu>

                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-dropdown-menu-160"
                    v-if="item.type == 'file'"
                  >
                    <el-dropdown-item
                      :command="item"
                      :type="it.type"
                      :index="i"
                      v-for="(it, index) in search_dropdown_items"
                      :divided="index== 5 || index == 8? true : false"
                      :key="index"
                      :disabled="(item.type == 'file' && it.is_share)"
                      >{{ item.is_collect == 'y' && it.text == "收藏" ? '取消收藏' : it.text  }}</el-dropdown-item
                    >
                  </el-dropdown-menu>

                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-dropdown-menu-160"
                    v-if="item.type != 'file' && item.type != 'dir'"
                  >
                    <el-dropdown-item
                      :command="item"
                      :type="it.type"
                      :index="i"
                      :divided="index== 5 || index == 8 ? true : false"
                      v-for="(it, index) in search_dropdown_items"
                      :key="index"
                      :disabled="it.type == 'pdf' || it.type == 'down'"
                      >
                      {{ item.is_collect == 'y' && it.text == "收藏" ? '取消收藏' : it.text  }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div
                class="file-collection file_collect"
                title="收藏当前图纸"
                @click="
                  collect_cur_dwg_my(
                    i,
                    item
                  )
                "
                v-if="item.is_collect == 'y' && item.type != 'dir'"
              >
                <img
                  :src="item.is_collect == 'n' ? is_collect_0 : is_collect_1"
                />
              </div>
              <div
                class="file-collection file_collect"
                title="收藏当前图纸"
                @click="
                  collect_cur_dwg_my(
                    i,
                    item
                  )
                "
                v-if="item.is_collect == 'n' && item.is_show_share && item.type != 'dir'"
              >
                <img
                  :src="item.is_collect == 'n' ? is_collect_0 : is_collect_1"
                />
              </div>
              <div
                class="file-share"
                title="生成网页链接发送给他人查看"
                v-show="item.is_show_share && item.type != 'dir'"
                @click="send_share_dwg_btn_my(item)"
              >
                发送图纸
              </div>
            </div>
          </div>
        </div>
        <div
          class="lately_list_no"
          v-if="$store.state.tableData_my_drawings.length == 0"
        >
          <div class="lately_center">
            <div class="lately_list_no_box">
              <img src="@/assets/images/team/no_file.png" alt="" />
              <div class="lately_list_no_th">暂无文件</div>
            </div>
          </div>
        </div>
      </div>

      <Movefile></Movefile>
      <IsSaveTwo></IsSaveTwo>
      <BatchShareDwg :allFiles="serchAllFiles"></BatchShareDwg>
      <ShareView2></ShareView2>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import { getUsage } from "@/api/user";
import Global from '@/views/dwg/global'
import {
  getFileList,
  openCloudfile,
  recent_add,
  myCloudCollection_yes,
  myCloudCollection_no,
  myCloudUpdateName,
  cloudlink_updname,
  myCloudDown,
  myCloudLinkDown,
  myCloudDel,
  cloudlink_del,
  myCloudDels,
  createDirectory,
  collect_cloudfile_m,
  cloudlink_copy,
  copyfile,
  move_cloud_file,
  cloudlink_upddir,
} from "@/api/get_cloud";

import { open_cloudfile_pdf, get_cloudfile_pdf_status } from '@/api/pdf'

import { get_share_code, online_sharefile_m } from "@/api/share";
import Movefile from "@/components/move_file";

import IsSaveTwo from "@/components/is_save_two.vue";

import BatchShareDwg from "@/components/batch_share_dwg.vue";

import ShareView2 from "@/components/share_box2.vue";

export default {
  name: "My_cloud",
  components: { Movefile, IsSaveTwo, BatchShareDwg, ShareView2 },
  data() {
    return {
      is_file: require("@/assets/images/cloud/file.png"),
      is_dir: require("@/assets/images/cloud/dir.png"),
      is_collect_0: require("@/assets/images/cloud/collect.png"),
      is_collect_1: require("@/assets/images/cloud/icon_richeng_star.png"),
      cur_parent_dir: "/",
      cur_click_item: null,
      cur_click_item_index: -1, // 当前点击行index
      is_show_more: false,
      dropdown_items: [
        { text: "打开", type: "open", is_dir: true, is_share: false, is_share1: false, },
        { text: "发送图纸", type: "share", is_dir: false, is_share: false, is_share1: false, },
        { text: "转PDF", type: "pdf", is_dir: false, is_share: false, is_share1: true, },
        { text: "下载图纸", type: "down", is_dir: false, is_share: false, is_share1: true, },
        { text: "重命名", type: "updatename", is_dir: true, is_share: false, is_share1: false,},
        { text: "复制", type: "copy", is_dir: false, is_share: false, is_share1: false,},
        { text: "剪切", type: "shear", is_dir: false, is_share: false, is_share1: false, },
        { text: "移动", type: "move", is_dir: false, is_share: false, is_share1: false, },
        { text: "收藏", type: "collection", is_dir: false, is_share: false, is_share1: false, },
        { text: "删除", type: "del", is_dir: true, is_share: false, is_share1: false, },
      ],
      search_dropdown_items: [
        { text: "打开", type: "open", is_dir: true, is_share: false, is_share1: false, },
        { text: "发送图纸", type: "share", is_dir: false, is_share: false, is_share1: false, },
        { text: "转PDF", type: "pdf", is_dir: false, is_share: false, is_share1: true, },
        { text: "下载图纸", type: "down", is_dir: false, is_share: false, is_share1: true, },
        { text: "重命名", type: "updatename", is_dir: true, is_share: false, is_share1: false,},
        { text: "复制", type: "copy", is_dir: false, is_share: false, is_share1: false,},
        { text: "剪切", type: "shear", is_dir: false, is_share: false, is_share1: false, },
        { text: "移动", type: "move", is_dir: false, is_share: false, is_share1: false, },
        { text: "收藏", type: "collection", is_dir: false, is_share: false, is_share1: false, },
        { text: "删除", type: "del", is_dir: true, is_share: false, is_share1: false, },
      ],
      right_menus_top: 0,
      right_menus_left: 0,
      is_show_right_menus: false,
      upload_btn_items: [
        {
          text: "文件",
          type: "upload_file",
          img: require("@/assets/images/team/cc-file.png"),
        },
        {
          text: "文件夹",
          type: "upload_dir",
          img: require("@/assets/images/team/cc-dir.png"),
        },
      ],
      cur_cloud_paths: [{ text: "CAD云盘", path: "/" }],
      is_loading: true,
      add_dir_input: "新建文件夹", //新建文件夹
      autofocus: false,
      checkAll: false, // 全选
      is_show_checkedAll: false, //是否显示全选
      isIndeterminate: true,
      checkAllText: "全选", //已选中多少条
      checkedItems: [],
      menus_down_action: true,
      menus_send_action: true,
      menus_sc_action: true,
      menus_update_action: true,
      menus_del_action: true,
      menus_paste_action: false,

      cur_cloud_sort_type: 'time',
      sortFiles: [],
      imgSrc1: require('@/assets/images/cloud/file_cloud.png'),
      imgSrc2: require('@/assets/images/cloud/file_form.png'),
      imgSrc3: require('@/assets/images/cloud/file_gg.png'),
      usertoken: '',

      dropdown_sort_items: [
        { text: "名称", type: "name", },
        { text: "日期", type: "time" },
        { text: "类型", type: "type" },
        { text: "大小", type: "size" },
      ],

      search_file_input: '',
      is_search_file: false,
      serchAllFiles: [], //搜索请求所有文件
      serchFiles: [], //关键字搜索文件


      timerArr: [],
    };
  },

  created() {},

  mounted() {
    var USER_TOKEN = JSON.parse(localStorage.getItem("USERTOKEN"));
    if (USER_TOKEN != null) {
      this.usertoken = USER_TOKEN
      var data = {
        p: this.$store.state.cur_parent_dir,
      };
      this.get_files(data);
    } else {
      this.login_expired_my()
    }
    var that = this;
    
    this.$EventBus.$off("getMyTableData");
    this.$EventBus.$on("getMyTableData", function () {
      // 开始调用方法
      var send = {
        p: that.$store.state.cur_parent_dir,
      };
      that.get_files(send);
    });
  },

  methods: {
    //登录过期，回到首页
    login_expired_my() {
      this.$msg.whiteerr('登录信息已过期，请重新登陆')
      utils.del_localStorae();
      this.$router.push("/");
    },

    //获取我的图纸
    get_files(send) {
      // if (!this.$store.state.is_show_up_history) {
      //   this.$store.state.tableData_loading_my_drawings = true;
      // }
      this.$store.state.tableData_loading_my_drawings = true;
      this.clear_checked_action()
      getFileList(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.login_expired_my();
          return;
        }
        var newData = [];
        this.sortFiles = []
        var dirs = []

        data.forEach((item) => {
          if (item.type == 'dir') {
            item.is_show_share = false;
            item.checked = false;
            item.input = false;
            item.is_share = 0
            item.checked = false;
            item.expired = false
            item.share_from = '文件夹'
            item.mtime1 = utils.convert_date_day(utils.getMyDate(item.mtime))
            dirs.push(item)
          } else {
            this.sortFiles.push(item)
          }
        });

        this.sortFiles.forEach((item)=>{
          item.is_show_share = false;
          item.checked = false;
          item.input = false;
          item.mtime1 = utils.convert_date_day(utils.getMyDate(item.mtime))
          item.expired = false
          item.is_share = 0
          if (item.type == 'dir') {
            item.size = "";
            item.share_from = '文件夹'
          } else {
            item.sizeMB = utils.convert_size_MB(item.size);
            if (item.type == 'file') {
              item.share_from = '我的云图纸'
              if (item.pdf != 1) {
                item.process = 0
              }
            } else {
              item.is_share = 1
              // item.expired = item.share_remain_time <= 0 ? true : false
            }
          }
        })


        this.cloud_files_sort()

        newData = [...dirs, ...this.sortFiles];
        this.$store.state.tableData_my_drawings = newData;
        this.$store.state.tableData_loading_my_drawings = false;
      });
    },

    //对云盘列表进行排序
    cloud_files_sort() {
      var cur_cloud_sort_type = this.$store.state.tableData_my_drawings_sort.type
      if (cur_cloud_sort_type == 'time') {
        this.sortFiles.sort(utils.compareTime('mtime','inverted'))
      } else if (cur_cloud_sort_type == 'size') {
        this.sortFiles.sort((a, b) => parseInt(b.size) - parseInt(a.size));
      } else if (cur_cloud_sort_type == 'name') {
        this.sortFiles.sort((a,b) => {
          return a.name.localeCompare(b.name, 'zh-Hans-CN', {sensitivity: 'accent'});
        })
      } else if (cur_cloud_sort_type == 'type') {
        this.sortFiles.sort(function (a) {
          if(a.type=="file")
            return 1;
          else
            return -1;
        });
      }
    },


    //排序操作
    dropdown_sort_btn_my(item) {
      this.$store.state.tableData_my_drawings_sort = {
        text: item.text,
        type: item.type
      }
      if (this.is_search_file == false) {
        this.set_tables_my1(this.$store.state.tableData_my_drawings)
      } else {
        this.set_tables_searchList(this.serchFiles)
      }
    },

    // 排序操作 1
    set_tables_my1(data) {
      this.sortFiles = []
      var dirs = []
      data.forEach((item)=>{
        item.is_show_share = false
        if (item.type == 'dir') {
          dirs.push(item)
        } else {
          this.sortFiles.push(item)
        }
      })
      this.cloud_files_sort()
      var newData = [...dirs, ...this.sortFiles]
      this.cur_click_item_index = -1
      this.$store.state.tableData_my_drawings = newData;
      this.$store.commit('updateMyTableData', this.$store.state.tableData_my_drawings)
    },
    // 搜素数据整理
    set_tables_searchList(data){
      this.sortFiles = []
      data.forEach((item)=>{
        item.is_show_share = false
        if (item.type == 'file') {
          let cloud_path = item.parent_dir + '/' + item.name;
          cloud_path = cloud_path.replace('//', '/');
          item.cloud_path = cloud_path.substr(0, 1).replace('/', '我的云图纸') + cloud_path
        }
        this.sortFiles.push(item)
      })
      this.cloud_files_sort()
      var newData = [...this.sortFiles]
      this.cur_click_item_index = -1
      this.serchFiles = newData
      if (this.search_file_input != '.') {
        this.changeColor(newData)
      }
    },

    changeColor(result) {
      result.map((item,index) => {
        if (this.search_file_input != '') {
          let replaceReg = new RegExp(this.search_file_input, 'ig')
          let replaceString = `<a style="color:#4383f0">${this.search_file_input}</a>`
          result[index].colorName = item.name.replace(replaceReg, replaceString)
        }
      })
      
      this.serchFiles = result
    },

    // 点击小搜索框
    click_serach_input () {
      this.getAllFileList()
    },

    click_search_btn () {
      this.getAllFileList()
    },

    changeSeachInput() {
      var serchFiles = []
      if (this.search_file_input != '' && this.search_file_input != '.') {
        serchFiles = this.filterText(this.search_file_input,this.serchAllFiles)
      } else {
        serchFiles = []
        this.serchFiles = serchFiles
      }
      this.set_tables_searchList(serchFiles)
    },

    updateChangeSeachInput () {
      var serchFiles = []
      if (this.search_file_input != '' && this.search_file_input != '.') {
        serchFiles = this.filterText(this.search_file_input,this.serchAllFiles)
      } else {
        serchFiles = []
        this.serchFiles = serchFiles
      }
      this.set_tables_searchList(serchFiles)
    },

    getAllFileList(use_type) {
      getFileList().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.login_expired_my();
          return;
        }
        var newData = [];
        this.sortFiles = []
        var dirs = []

        data.forEach((item) => {
          if (item.type != 'dir') {
            this.sortFiles.push(item)
          }
        });

        this.sortFiles.forEach((item)=>{
          item.is_show_share = false;
          item.checked = false;
          item.input = false;
          item.mtime1 = utils.convert_date_day(utils.getMyDate(item.mtime))
          item.expired = false
          item.is_share = 0
          if (item.type == 'dir') {
            item.size = "";
            item.share_from = '文件夹'
          } else {
            item.sizeMB = utils.convert_size_MB(item.size);
            if (item.type == 'file') {
              item.share_from = '我的云图纸'
              let cloud_path = item.parent_dir + '/' + item.name;
              cloud_path = cloud_path.replace('//', '/');
              item.cloud_path = cloud_path.substr(0, 1).replace('/', '我的云图纸') + cloud_path
              if (item.pdf != 1) {
                item.process = 0
              }
            } else {
              item.is_share = 1
              // item.expired = item.share_remain_time <= 0 ? true : false
              item.cloud_path = item.share_from
            }
          }
        })


        this.cloud_files_sort()
        newData = [...dirs, ...this.sortFiles];
        this.serchAllFiles = newData;

        if (use_type == 'batch') return
        this.is_search_file = true

        this.$nextTick(()=>{
          this.$refs.searchfileinput.focus()
        })

        if (this.search_file_input != '') {
          // this.set_tables_searchList(this.serchFiles)
          this.updateChangeSeachInput(this.serchFiles)
        }

        
      })
    },


    filterText(text,arr){
      //filter函数，筛选作用，将return true的elem拼成一个数组并返回
      return arr.filter(function(elem,index){
        if(elem.name.indexOf(text)!=-1){
          return true;
        }else{
          return false;
        }
      });
    },

    //刷新
    refresh_lists_btn_my() {
      this.$store.state.tableData_loading_my_drawings = true;
      var send = {
        p: this.$store.state.cur_parent_dir,
      };
      this.get_files(send);
    },

    is_show_share_click(i) {
      this.cur_click_item_index = i
      if (this.is_search_file == false) {
        this.$store.state.tableData_my_drawings.forEach((item)=>{
          item.is_show_share = false
        })
        this.$store.state.tableData_my_drawings[i].is_show_share = true;
        this.$store.commit('updateMyTableData', this.$store.state.tableData_my_drawings)
      } else {

        this.serchFiles.forEach((item)=>{
          item.is_show_share = false
        })
        this.serchFiles[i].is_show_share = true;
      }
      
    },

    show_share_btn(i) {
      if (this.is_search_file == false) {
        this.$store.state.tableData_my_drawings[i].is_show_share = true;
        this.$store.commit('updateMyTableData', this.$store.state.tableData_my_drawings)
      } else {
        this.$forceUpdate()
        this.$set(this.serchFiles[i], 'is_show_share', true)
      }
      
    },

    hide_share_btn(i) {
      if (this.cur_click_item_index == i) return
      if (this.is_search_file == false) {
        this.$store.state.tableData_my_drawings[i].is_show_share = false;
        this.$store.commit('updateMyTableData', this.$store.state.tableData_my_drawings)
      } else {
        this.$forceUpdate()
        this.$set(this.serchFiles[i], 'is_show_share', false)
      }
     
    },

    collect_cur_dwg_my(i, item) {
      if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法操作');return}
      var { link_id, is_share, parent_dir, name, is_collect }  = item
      var send;
      if (is_share == 1){ 
        send = {
          usertoken: this.usertoken,
          link_id: link_id,
        }
      } else {
        var cloud_path = parent_dir + '/' + name;
        var cloud_path_new = cloud_path.replace('//', '/');
        send = {
          usertoken: this.usertoken,
          cloud_path: cloud_path_new,
        }
      }

      if (is_collect == 'n') {
        myCloudCollection_yes(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            this.login_expired_my();
          } else if (data.code == 1) {
            this.$store.state.tableData_my_drawings[i].is_collect = 'y';
            this.$msg.white('已成功收藏')
            
            if (this.is_search_file) {this.collectUpdateAllFiles(item, 'y')}
          }
        });
      } else {
        myCloudCollection_no(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            this.login_expired_my();
          } else if (data.code == 1) {
            this.$store.state.tableData_my_drawings[i].is_collect = 'n';
            this.$msg.white('已取消收藏')
            
            if (this.is_search_file) {this.collectUpdateAllFiles(item, 'n')}
          }
        });
      }
    },


    open_dwg_my(item) {
      if (item.type == 'dir') {
        var path = item.parent_dir + "/" + item.name;
        path = path.replace("//", "/");
        var send = {
          p: path,
        };
        this.$store.state.cur_parent_dir = path;
        this.set_cloud_path(item.name, path);
        this.get_files(send);
      } else {
        if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法打开');return}
        if (item.type != 'file') {
          this.open_share_url(item)
          return
        }
        let cloud_path = item.parent_dir + "/" + item.name;
        cloud_path = cloud_path.replace("//", "/");
        var send1 = {
          cloud_path: cloud_path,
          is_mobile: "0",
        };
        // const tempPage = window.open('', '_blank')
        console.log('openCloudfile desk')
        openCloudfile(send1).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            var f_id = data.f_id;
            // this.$router.push({ name: "dwg", params: { f_id: f_id } });
            let file_type = data.file_type
            if (file_type == '3d') {
              let pathInfo = this.$router.resolve({
                path: "/cdf",
                query: {
                  file_id: f_id,
                },
              });
              var path = item.parent_dir + "/" + item.name;
              path = path.replace("//", "/"); 
              this.open_file_recent_add (item.type, path)
              window.open(pathInfo.href, "_blank");
              // tempPage.location = pathInfo.href
              // this.winBlankOpen(pathInfo.href)
            } else {
              let pathInfo = this.$router.resolve({
                path: "/dwg",
                query: {
                  file_id: f_id,
                },
              });
              var path = item.parent_dir + "/" + item.name;
              path = path.replace("//", "/"); 
              this.open_file_recent_add (item.type, path)
              console.log('openCloudfile desk')
              window.open(pathInfo.href, "_blank");
              // tempPage.location = pathInfo.href
              // this.winBlankOpen(pathInfo.href)
            }
          } else if (res.code == 2) {
            this.$msg.whiteerr('文件不存在，可能已被删除')
          } else if (res.code == 3) {
            this.$msg.whiteerr('不支持文件类型')
          } else {
            this.$msg.whiteerr('未知原因，无法打开')
          }
        }).catch(()=>{
          this.$msg.whiteerr('网络开小差了，请稍后再试')
        });
      }
    },

    winBlankOpen(url) {
      $('body').append('<a target="_blank" href=' + url + ' id="j_winBlankOpen"></a>');
      $('#j_winBlankOpen')[0].click();
      $('#j_winBlankOpen').remove();
    },

    open_share_url(item) {
      if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法分享');return}
      if (item.file_type == '3d') {
        let pathInfo = this.$router.resolve({
        path: "/cdf",
        query: {
          share_id: item.share_id,
        },
      });
      window.open(pathInfo.href, "_blank");
      return
      }
      let pathInfo = this.$router.resolve({
        path: "/dwg",
        query: {
          share_id: item.share_id,
          link_id: item.link_id ? item.link_id : ''
        },
      });
      window.open(pathInfo.href, "_blank");
    },

    open_pdf(item){
      if (item.pdf == 1) { //已转换PDF
        let pathInfo = this.$router.resolve({
          path: "/pdf",
          query: {
            file_id: item.f_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      } else {
        open_cloudfile_pdf({
          'parent_dir': item.parent_dir,
          'filename': item.name
        }).then(res=>{
          var res = JSON.parse(res.data)
          
          if (res.code == 1) {
            let arr = this.$store.state.cloudfile_wait_pdfs
            arr.push(res.f_id)
            let newArr =[...new Set(arr)];
            
            this.$store.state.cloudfile_wait_pdfs = newArr

            this.getCloudfilePDFStatus(res.f_id,item.name)
          }
        })
      }
    },

    getCloudfilePDFStatus(f_id,name) {
      var that = this
      var cloudfile_pdf_timer = setInterval(function () {
        get_cloudfile_pdf_status({
            'f_id': f_id
          }).then(res=>{
            var res = JSON.parse(res.data)
            if (res.code == 1) {
              if (res.status == 0) {
                that.set_file_pdf_process(name, f_id, res.status)
              } else if (res.status == 1) {
                that.set_file_pdf_process(name, f_id, res.status)
              } else if (res.status == 2) {
                clearInterval(cloudfile_pdf_timer);
                var files = that.$store.state.tableData_my_drawings
                files.map((item) => {
                  if (item.name == name) {
                    item.f_id = ''
                    that.$set(item, 'f_id', f_id)
                  }
                })
                that.set_file_pdf_process(name, f_id, res.status)
              }else if (res.status == 3) {
                clearInterval(cloudfile_pdf_timer);
                that.set_file_pdf_process(name, f_id, res.status)
              }
            } else if (res.code == 2) {
                clearInterval(cloudfile_pdf_timer);
                that.set_file_pdf_process(name, f_id, 2)
            } else {
              clearInterval(cloudfile_pdf_timer);
              that.set_file_pdf_process(name, f_id, 3)
              console.log('error');
            }
          }).catch(()=>{
            clearInterval(cloudfile_pdf_timer);
            that.set_file_pdf_process(name, f_id, 3)
          })
      },2000)
    },


    set_file_pdf_process (name,f_id,status) {
      var files = this.$store.state.tableData_my_drawings
      var process = 0
      if (status == 0) {
        process = 10
      } else if (status == 1) {
        process = 40
      }else if (status == 2) {
        process = 100
      } else if (status == 3) {
        process = 0
      }

      files.map((item) => {
        if (item.name == name) {
          this.$set(item, 'process', process)
          if (process == 100) {
            this.$set(item, 'pdf', 1)
            this.$set(item, 'process', 0)
          }

          console.log(item)
        }
      })

      
    },

    //打开文件，添加到最近
    open_file_recent_add (type, value) {
      var send;
      if (type == 'file') {
        var cloud_path = value
        send = {
          usertoken: this.usertoken,
          cloud_path: cloud_path
        }
      }
      recent_add(send).then(()=>{})
    },


    //设置当前路径组
    set_cloud_path(text, path) {
      this.isIndeterminate = true;
      this.$store.state.cur_cloud_paths.push({
        text: text,
        path: path,
      });
    },

    //点击从路径跳转
    go_to_cloud_path(path, i) {
      var data = {
        p: path,
      };
      if (this.$store.state.cur_parent_dir == path) {
        return;
      }
      this.$store.state.cur_parent_dir = path;
      this.get_files(data);
      this.update_cloud_path(i);
    },

    //修改路径组
    update_cloud_path(i) {
      this.isIndeterminate = true;
      if (i == 0) {
        this.$store.state.cur_cloud_paths = [{ text: "CAD云盘", path: "/" }];
      } else {
        this.$store.state.cur_cloud_paths.splice(i + 1);
      }
    },

    //操作
    dropdown_item_btn_my(item, event) {
      var type = event.$attrs.type;
      var i = event.$attrs.index;
      
      if (item.type != "dir") {
        //文件
        if (type == "open") {
          //打开
          this.open_dwg_my(item);
        } else if (type == "share") {
          if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法分享');return}
          this.send_share_dwg_btn_my(item)
        } else if (type == "pdf") {
          if (item.file_type == '3d') {
            this.$msg.whiteerr('该文件格式不支持转PDF')
          } else {
            this.open_pdf(item)
          }
        } else if (type == "collection") {
          // 收藏
          this.collect_cur_dwg_my(i, item);
        }  else if (type == "copy") {
          // 复制
          this.$store.state.copy_cur_item_data = item
          this.$store.state.copy_cur_item_data_type = 'copy'
          this.$store.state.menus_paste_action = true
          this.$msg.white('复制成功，可粘贴至其他位置')
        }  else if (type == "shear") {
          // 剪切
          this.$store.state.copy_cur_item_data = item
          this.$store.state.copy_cur_item_data_type = 'shear'
          this.$store.state.menus_paste_action = true
          this.$msg.white('剪切成功，可粘贴至其他位置')
        } else if (type == "move") {
          // 移动
          this.$store.state.is_show_move_dwg_box = true
          this.$EventBus.$emit("getDirLists" , JSON.stringify(item))
        } else if (type == "updatename") {
          // 重命名
          this.show_updateName_box(item);
        } else if (type == "down") {
          // 下载
          if (item.type == 'file') {
            this.down_cloudlist_item(item.parent_dir, item.name)
          } else {
            if (item.type == 'linkfile') {
              this.$msg.whiteerr('没有下载权限，无法下载该文件')
            }
          }
        } else if (type == "del") {
          // 删除
          this.show_del_box_my(item, i);
        }
      } else {
        if (type == "open") {
          //文件夹
          //打开
          this.open_dwg_my(item);
        } else if (type == "updatename") {
          // 重命名
          this.show_updateName_box(item);
        } else if (type == "del") {
          // 删除
          this.show_del_box_my(item, i);
          // this.delfile(item.parent_dir, item.name,item.id)
        }
      }
    },

    // 右键显示菜单
    show_right_menus_cloud(event,item, i) {
      this.cur_click_item = item
      this.cur_click_item_index = i
      this.is_show_share_click(i)
      var that = this
      this.$contextmenu({
        items: [
          {
            label: "打开",
            onClick: () => {
              that.open_dwg_my(item);
            }
          },
          { label: "发送图纸",
            disabled: item.type != 'dir' ? false : true,
            onClick: () => {
              that.send_share_dwg_btn_my(item)
            }
          },
          { label: item.pdf == 0 ? "转PDF" : '打开PDF',
            disabled: item.type == 'file' && item.file_type == '2d' ? false : true,
            onClick: () => {
              that.open_pdf(item)
            },
          },
          { label: "下载图纸", disabled: item.type == 'file' ? false : true,
            onClick: () => {
              if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法下载');return}
              if (item.type == 'file') {
                this.down_cloudlist_item(item.parent_dir, item.name)
              } else {
                if (item.type == 'linkfile') {
                  this.$msg.whiteerr('没有下载权限，无法下载该文件')
                }
              }
            }
          },
          { label: "重命名", divided: true,
            onClick: () => {
              that.show_updateName_box(item);
            }
          },
          { label: "复制", disabled: item.type != 'dir' ? false : true, 
            onClick: () => {
              that.$store.state.copy_cur_item_data = item
              that.$store.state.copy_cur_item_data_type = 'copy'
              that.$store.state.menus_paste_action = true
              that.$msg.white('复制成功，可粘贴至其他位置')
            }
          },
          { label: "剪切", disabled: item.type != 'dir' ? false : true,
            onClick: () => {
              that.$store.state.copy_cur_item_data = item
              that.$store.state.copy_cur_item_data_type = 'shear'
              that.$store.state.menus_paste_action = true
              that.$msg.white('剪切成功，可粘贴至其他位置')
            }
          },
          { label: "移动" , divided: true, disabled: item.type != 'dir' ? false : true,
            onClick: () => {
              that.$store.state.is_show_move_dwg_box = true
              that.$EventBus.$emit("getDirLists" , JSON.stringify(item))
            }
          },
          { label: item.is_collect == 'n' ? "收藏" : "取消收藏", disabled: item.type != 'dir' ? false : true, onClick: () => {
              that.collect_cur_dwg_my(i, item);
            }
          },
          { label: "删除",
            onClick: () => {
              that.show_del_box_my(item, i);
            }
          },
        ],
        event,
        //x: event.clientX,
        //y: event.clientY,
        customClass: "class-a",
        zIndex: 3,
        minWidth: 160
      });
      return false;
    },

    //显示删除确定框
    show_del_box_my(item, i) {
      var text = "文件";
      if (item.type == "dir") {
        text = "文件夹";
      } else if (item.type == 'file') {
        text = "文件";
      } else if (item.type == 'link') {
        text = "分享文件记录";
      }
      
      this.$confirm("此操作将删除该" + text + ", 是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "alert_del_btn",
        customClass: "cover_messageBox",
      })
        .then(() => {
          this.delfile(item, i);
        })
        .catch(() => {
        });
    },

    // 删除文件
    delfile(item, i) {
      var { parent_dir, name, is_share, link_id } = item
      var send;
      var __this = this;
      if (is_share == 0) {
        var p = parent_dir + "/" + name;
        p = p.replace("//", "/");
        send = {
          p: p,
        }
        myCloudDel(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            __this.login_expired_my();
          } else if (data.code == 1) {
            __this.$store.state.tableData_my_drawings.splice(i, 1);
            __this.$msg.white('删除成功')
            __this.get_Usage();
            __this.updateCheckItems(name)
            if (__this.is_search_file) {
              __this.deleteUpdateAllFiles(item)
            }
          }
        });
      } else {
        send = {
          usertoken: __this.usertoken,
          link_id: link_id
        }
        cloudlink_del(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            __this.login_expired_my();
          } else if (data.code == 1) {
            __this.$store.state.tableData_my_drawings.splice(i, 1);
            __this.$msg.white('删除成功')
            __this.get_Usage();
            __this.updateCheckItems(name)
            if (__this.is_search_file) {
              __this.deleteUpdateAllFiles(item)
            }
          }
        });
      }
      
    },

    updateCheckItems(filename) {
      var newCheckedItems = []
      var items = this.$store.state.checkedItems
      items.forEach((item)=>{
        if (item.name == filename) {
          item = null
        }
        if (item != null) {
          newCheckedItems.push(item)
        }
      })
      this.$store.state.checkedItems = newCheckedItems
    },

    get_Usage() {
      getUsage().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          return;
        } else {
          this.$store.state.usage = utils.convert_size_MB(data.usage);
          this.$store.state.total = utils.convert_size_MB(data.total);
          this.$store.state.percentage =
            ((data.usage / data.total) * 100).toFixed(0) - 0;
        }
      });
    },

    show_updateName_box(item) {
      this.$prompt("", "重命名", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^((?![\\|\\ \\"\\?\\>\\<\\:\\.\\'\\/\\*\\]).)*$/,
        inputErrorMessage: "不能使用特殊字符或空格",
        closeOnClickModal: false,
        customClass: "updateName_messageBox",
        inputValue: item.type == 'dir' ? item.name : utils.getFileName(item.name),
      })
        .then(({ value }) => {
          if (value == "" || value == null) {
            this.$msg.whiteerr('新名称不能为空')
            return;
          }
          if (value.length >= 16) {
            this.$msg.whiteerr('新名称长度不能超过16个字符')
            return;
          }

          this.updateName(item, value);
        })
        .catch(() => {
        });
    },

    updateName(item, new_name) {
      var old_name = item.name;
      var send;
      var lastName = utils.getExtensiona(old_name)
      if (item.is_share == 0) {
        var p = item.parent_dir + "/" + old_name;
        p = p.replace("//", "/");
        send = {
          newname: item.type == "dir" ? new_name : new_name + lastName,
          path: p,
          type: item.type,
        };
        myCloudUpdateName(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            var arr = this.$store.state.tableData_my_drawings;
            arr.map((it) => {
              if (it.name == old_name) {
                it.name = item.type == "dir" ? new_name : new_name + lastName;
              }
            });
            this.$msg.white('修改成功')
            if (this.is_search_file) {
              this.updateAllFiles(item, new_name + lastName)
            }
          } else if (data.code == 3) {
            this.$msg.whiteerr(data.msg)
          } else if (data.code == 99) {
            this.login_expired_my();
          }
        });
      } else {
        send = {
          'usertoken': this.usertoken,
          'newname': new_name + lastName,
          'link_id': item.link_id
        }
        cloudlink_updname(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            var arr = this.$store.state.tableData_my_drawings;
            arr.map((it) => {
              if (it.name == old_name) {
                it.name = new_name + lastName;
              }
            });
            this.$msg.white('修改成功')
            if (this.is_search_file) {
              this.updateAllFiles(item, new_name + lastName)
            }
          } else if (data.code == 3) {
            this.$msg.whiteerr(data.msg)
          } else if (data.code == 22) {
            this.$msg.whiteerr('已存在同名文件或目录');
          } else if (data.code == 99) {
            this.login_expired_my();
          }
        });
      }
    },

    // 在搜索中修改文件名,文件列表（渲染），全部搜索文件列表，搜索文件列表都需要更新
    updateAllFiles (data,newName) {
      let oldName = data.name
      var parent_dir = data.parent_dir
      var { serchAllFiles, serchFiles  } = this
      var tableData_my_drawings = this.$store.state.tableData_my_drawings
        serchAllFiles.forEach((item)=>{
          if (item.name == oldName && item.parent_dir == parent_dir) {
            item.name = newName
          }
        })

        serchFiles.forEach((item)=>{
          if (item.name == oldName && item.parent_dir == parent_dir) {
            item.name = newName
          }
        })

        tableData_my_drawings.forEach((item)=>{
          if (item.name == oldName) {
            item.name = newName
          }
        })

        this.set_tables_searchList(serchFiles)

    },

    // 在搜索种删除文件名
    deleteUpdateAllFiles(item) {
      var { serchAllFiles, serchFiles  } = this
      var tableData_my_drawings = this.$store.state.tableData_my_drawings
      const index1 = serchFiles.findIndex(function(val){
        return val.name === item.name && val.parent_dir == item.parent_dir 
      })
      const index2 = serchAllFiles.findIndex(function(val){
        return val.name === item.name && val.parent_dir == item.parent_dir 
      })
      const index3 = tableData_my_drawings.findIndex(function(val){
        return val.name === item.name && val.parent_dir == item.parent_dir 
      })

      if (index1 == -1) return
      serchFiles.splice(index1,1)
      if (index2 == -1) return
      serchAllFiles.splice(index2,1)
      if (index3 == -1) return
      tableData_my_drawings.splice(index3,1)
    },

    // 在搜索中收藏
    collectUpdateAllFiles(data, is_collect) {
      let oldName = data.name
      var parent_dir = data.parent_dir
      var { serchAllFiles, serchFiles  } = this
      var tableData_my_drawings = this.$store.state.tableData_my_drawings
        serchAllFiles.forEach((item)=>{
          if (item.name == oldName && item.parent_dir == parent_dir) {
            item.is_collect = is_collect
          }
        })

        serchFiles.forEach((item)=>{
          if (item.name == oldName && item.parent_dir == parent_dir) {
            item.is_collect = is_collect
          }
        })

        tableData_my_drawings.forEach((item)=>{
          if (item.name == oldName && item.parent_dir == parent_dir) {
            item.is_collect = is_collect
          }
        })
    },

    //发送图纸
    send_share_dwg_btn_my(item) {
      if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法分享');return}
      this.$store.state.share_from = 'mycloud'
      this.$store.state.is_show_share_box = true;
      this.$store.state.cur_share_data = item;
      this.$store.commit("updateCurShareData_cloud", item);
      this.$EventBus.$emit("showShareBox");
      // this.getShareQcode_my(item);
    },

    //获取分享地址
    getShareQcode_my(item) {
      // var type = this.$store.state.cur_show_action;
      this.$store.state.share_box_url = "正在努力获取中...";
      if (item.is_share == 1) {
        this.$store.state.share_box_url = item.share_url;
        return
      }
      var share_box_p;
      if (item.parent_dir == "/") {
        share_box_p = item.parent_dir + item.name;
      } else {
        share_box_p = item.parent_dir + "/" + item.name;
      }
      var send = {
        type: 0,
        p: share_box_p,
      };
      
      get_share_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          this.$store.state.share_box_url = url;
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已经删除')
        } else if (data.code == 99) {
          this.login_expired_my();
        }
      });
    },

    // 上传文件按钮选择
    upload_item_btn(item, event) {
      var type = event.$attrs.type;
      if (type == "upload_file") {
        this.$EventBus.$emit("uploadFile");
      } else if (type == "upload_dir") {
        this.$EventBus.$emit("uploadDir");
      }
    },

    show_create_box_btn() {
      this.$store.state.is_show_create_dwg_box = true;
      this.$store.state.cur_checked_path = this.$store.state.cur_parent_dir
    },

    // 批量分享图纸
    batch_share_dwg() {
      this.$store.state.show_batch_share = true;
      this.getAllFileList('batch');
    },

    //显示新建文件夹
    add_dir_item() {
      var items = this.$store.state.tableData_my_drawings;
      var add_item = {
        type: "dir",
        parent_dir: "/",
        name: "",
        size: "",
        paf: 0,
        id: "add",
        is_collect: 0,
        is_show_more: false,
        mtime: "",
        checked: false,
        input: true,
      };
      if (items.length == 0) {
        items.unshift(add_item);
        this.autofocus = true;

        this.$nextTick( () => {
          this.$refs.addDirInput[0].focus();
        });
        return;
      }

      if (items[0].id == "add") {
        return;
      }

      items.unshift(add_item);
      this.autofocus = true;

      this.$nextTick(() => {
        this.$refs.addDirInput[0].focus();
      });
    },

    create_newdir_onSubmit() {
      this.create_newdir_yes();
    },

    create_newdir_yes() {
      var new_dir_name = this.add_dir_input;
      var cloud_path_cur = this.$store.state.cur_parent_dir;
      var ss = this.is_dir_name(new_dir_name);
      var send = {
        p:
          cloud_path_cur +
          "/" +
          (ss ? this.check_dirname(new_dir_name) : new_dir_name),
      };
      createDirectory(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$msg.white('新建成功')

          var send = {
            p: this.$store.state.cur_parent_dir,
          };
          this.get_files(send);
          this.add_dir_input = "新建文件夹";
        } else if (data.code == 2) {
          this.$msg.whiteerr('已有该名称~')

        } else if (data.code == 99) {
          this.login_expired_my();
        } else {
          this.$msg.whiteerr('创建失败，请稍后在试~')

        }
      });
    },

    create_newdir_no() {
      this.$store.state.tableData_my_drawings.shift();
    },

    // 重名
    check_dirname(dirName) {
      // file_lists 当前文件列表
      var idX = 1;
      var is = true;
      var newDirName = "";
      while (is) {
        newDirName = dirName + idX;
        is = this.is_dir_name(newDirName);
        idX++;
        if (idX == 999) {
          is = false;
        }
      }
      return newDirName;
    },

    is_dir_name(newDirName) {
      var file_lists = this.$store.state.tableData_my_drawings;
      var is_dir_name = false;
      file_lists.forEach((item) => {
        if (newDirName == item.name) {
          is_dir_name = true;
        }
      });
      return is_dir_name;
    },

    //全选
    handleCheckAllChange() {
      if (this.isIndeterminate) {
        this.isIndeterminate = false;
        var items = this.$store.state.tableData_my_drawings;
        this.$store.state.checkedItems = []
        items.forEach((item) => {
          item.checked = true;
          this.$store.state.checkedItems.push(item);
        });
        this.checkAll = true;
        this.checkAllText = "已选中" + items.length + "条";
      } else {
        var items1 = this.$store.state.tableData_my_drawings;
        items1.forEach((item) => {
          item.checked = false;
        });
        this.clear_checked_action()
      }
    },

    handleCheckedMyTableChange(item) {
      var items = this.$store.state.tableData_my_drawings;

      // 选中数组 $store.state.checkedItems
      //true时 加入， false 减少

      if (item.checked) {
        this.is_show_checkedAll = true;
        this.push_checked_items(item);
      } else {
        this.is_show_checkedAll = true;
        this.del_checked_items(item.name);
      }

      //检测全选按钮状态 是否为全选
      if (items.length == this.$store.state.checkedItems.length) {
        // 全选
        this.checkAll = true;
        this.inputPattern = false;
        this.checkAllText = "已选中" + this.$store.state.checkedItems.length + "条";
      } else {
        // 未全选
        this.checkAll = false;
        this.inputPattern = true;
        this.checkAllText = "已选中" + this.$store.state.checkedItems.length + "条";
      }

      if (this.$store.state.checkedItems.length == 0) {
        this.checkAll = false;
        this.inputPattern = false;
        this.is_show_checkedAll = false;
        this.checkAllText = "全选";
      }

      //选择文件及文件夹按钮禁用情况
      this.is_type_close_btn();
    },

    // 添加、选中项
    push_checked_items(item) {
      this.$store.state.checkedItems.push(item);
    },
    // 移除、选中项
    del_checked_items(name) {
      var new_checked_items = [];
      if (this.$store.state.checkedItems.length == 1) {
        this.$store.state.checkedItems = [];
        return;
      }
      this.$store.state.checkedItems.forEach((item) => {
        if (item.name != name) {
          new_checked_items.push(item);
        }
      });

      console.log(name)
      this.$store.state.checkedItems = new_checked_items;
    },

    // 检查是否类型不同处理
    is_type_close_btn() {
      var checked_items = this.$store.state.checkedItems;
      // 选中一个时按钮的状态
      if (checked_items.length == 0) {
        this.clear_checked_action();
        return;
      }
      if (checked_items.length == 1) {
        this.is_checked_file_or_dir1();
        return;
      }
      // 选中两个以上时的按钮状态
      if (checked_items.length >= 2) {
        this.is_checked_file_or_dir2();
      }
    },

    // 无任何选择清除状态
    clear_checked_action() {
      this.$store.state.checkedItems = [];
      this.checkAll = false;
      this.is_show_checkedAll = false;
      this.isIndeterminate = true;
      this.checkAllText = "全选";
      this.menus_down_action = true;
      this.menus_send_action = true;
      this.menus_sc_action = true;
      this.menus_update_action = true;
      this.menus_del_action = true;
    },

    is_checked_file_or_dir1() {
      // 选中一个时，文件还是文件夹
      var checked_items = this.$store.state.checkedItems;
      if (checked_items.length == 1) {
        if (checked_items[0].type == "dir") {
          //当选中为文件夹时
          this.menus_down_action = true;
          this.menus_send_action = true;
          this.menus_sc_action = true;
          this.menus_update_action = false;
          this.menus_del_action = false;
        } else {
          //当选中为文件时
          if (checked_items[0].type == 'file') {
            this.menus_down_action = false;
            this.menus_send_action = false;
            this.menus_sc_action = false;
            this.menus_update_action = false;
            this.menus_del_action = false;
          } else {
            this.menus_down_action = true;
            this.menus_send_action = false;
            this.menus_sc_action = false;
            this.menus_update_action = false;
            this.menus_del_action = false;
          }
          
        }
        return;
      }
    },

    is_checked_file_or_dir2() {
      // 选择2个，2个以上
      var checked_items = this.$store.state.checkedItems;
      var is_dir = false;
      var is_file = false;
      checked_items.forEach((item) => {
        if (item.type == "dir" || item.type == 'link' || item.type == 'linkfile') {
          is_dir = true;
        } else {
          is_file = true;
        }
      });
      if (is_dir == true && is_file == true) {
          this.menus_down_action = true;
          this.menus_send_action = true;
          this.menus_sc_action = true;
          this.menus_update_action = true;
          this.menus_del_action = false;
        return;
      }
      if (is_dir == true && is_file == false) {
        this.menus_down_action = true;
        this.menus_send_action = true;  
        this.menus_sc_action = true;
        this.menus_update_action = true;
        this.menus_del_action = false;
        return;
      }
      if (is_dir == false && is_file == true) {
        this.menus_down_action = false;
        this.menus_send_action = false;
        this.menus_sc_action = false;
        this.menus_update_action = true;
        this.menus_del_action = false;
        return;
      }
    },

    //顶部菜单操作
    // 批量管理 - 下载
    DOWN_FILES() {
      var items = this.$store.state.checkedItems;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type == 'file') {
          this.down_cloudlist_item(items[i].parent_dir, items[i].name)
        }
      }
    },
    // 批量管理 - 发送图纸
    SEND_DWGS_MY(use_type) {
      var items = this.$store.state.checkedItems;
      if (use_type == 'batch') {
        items = this.$store.state.batchCheckedItems;
      }

      var share_list = []
      this.$store.state.share_from = 'myclouds'
      if (use_type == 'batch' || use_type == 'batch_hadChose') {
        this.$store.state.is_show_share_box2 = true;
      } else {
        this.$store.state.is_show_share_box = true;
      }
      this.$store.state.cur_share_data = items[0];
      this.$store.commit("updateCurShareData_cloud", this.$store.state.cur_share_data);
      var type = this.$store.state.cur_show_action
      if (items.length == 1 && items[0].type == 'link' || items[0].type == 'linkfile') {
        if (type == "url") {
            // url
            this.$store.state.share_box_url = items[0].share_url;
          } else {
            // qrcode
            this.creatQrCode(items[0].share_url);
          }
        return
      }

      if (use_type == 'batch'|| use_type == 'batch_hadChose') {
        this.$EventBus.$emit("showShareBox2", use_type);
      } else {
        this.$EventBus.$emit("showShareBox");
      }
      return

      items.forEach((item) => {
        if ( item.type == 'file') {
          var p = item.parent_dir + '/' + item.name
          p = p.replace('//', '/');
          share_list.push(p)
        }
      })

      var send = {
        type: 0,
        ps: JSON.stringify(share_list)
      }
      
      this.$store.state.share_box_url = "正在努力获取中...";
      online_sharefile_m(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          var url = data.url;
          this.$store.state.share_box_url = url;
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已经删除~')
        } else if (data.code == 99) {
          this.login_expired_my();
        }
      })
    },
    // 批量管理 - 收藏
    SHOUCANG_DWGS() {
      var items = this.$store.state.checkedItems;
      var filenames = []
      items.forEach((item) => {
        if (item.type == 'file') {
          filenames.push(item.name)
        }
      })
      var cloud_path_cur = this.$store.state.cur_parent_dir
      var send = {
        parent_dir: cloud_path_cur,
        filenames: JSON.stringify(filenames)
      }

      collect_cloudfile_m(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          this.$msg.white('批量收藏文件成功')
          // this.get_files()
          var files = this.$store.state.tableData_my_drawings
          files.forEach((item)=> {
            filenames.forEach((it)=>{
              if (item.name == it) {
                item.is_collect = 1
              }
            })
          })
        } else if (data.code == 99) {
          this.login_expired_my()
        }
      })

    },
    // 批量管理 - 重命名
    UPDATE_NAME() {
      var item = this.$store.state.checkedItems[0];
      this.show_updateName_box(item)
    },
    // 批量管理 - 删除
    DELETE_LISTS() {
      this.show_dels_box_my()
    },
    // 批量管理 - 刷新
    REFRESH_LISTS() {
      this.clear_checked_action();
      var data = {
        p: this.$store.state.cur_parent_dir,
      };
      this.get_files(data);
    },

    // 批量管理 - 粘贴
    PASTE_FILE () {
      var cur_copy_data = this.$store.state.copy_cur_item_data
      var cur_zhantie_action = this.$store.state.copy_cur_item_data_type
       if (cur_zhantie_action == 'copy') {
        if (cur_copy_data.type == 'file') {
            cur_copy_data.to_dir = this.$store.state.cur_parent_dir
        } else {
            cur_copy_data.dir = this.$store.state.cur_parent_dir
        }
        this.copy_cloudlink_file(cur_copy_data)
      }

      if (cur_zhantie_action == 'shear') {
        if (cur_copy_data.type == 'file') {
            cur_copy_data.to_dir = this.$store.state.cur_parent_dir
        } else {
          cur_copy_data.dir = this.$store.state.cur_parent_dir
        }
        this.shear_cloudlink_file(cur_copy_data)
      }
    },

    // 复制
    copy_cloudlink_file(cur_copy_data) {
      var send
      if (cur_copy_data.type == 'file') {
        send = {
          usertoken: this.usertoken,
          from_dir: cur_copy_data.parent_dir,
          filename: cur_copy_data.name,
          to_dir: cur_copy_data.to_dir
        }

        copyfile(send).then(res=>{
          var data = JSON.parse(res.data)
          if (data.code == 1) {
              var send = { p: this.$store.state.cur_parent_dir };
              this.get_files(send);
          } else if (data.code == 2) {
            //同名 显示是否覆盖框
            this.$store.state.is_show_cover_files_box = true
            this.$msg.whiteerr('已存在同名目录或文件')
          } else if (data.code == 3) {
            this.$msg.whiteerr('所复制的文件不存在或已被删除')
          }
        })
      } else {
        send = {
          usertoken: this.usertoken,
          link_id: cur_copy_data.link_id,
          force: false,
          dir: cur_copy_data.dir
        }

        cloudlink_copy(send).then(res=>{
          var data = JSON.parse(res.data)
          if (data.code == 1) {
              var send = { p: this.$store.state.cur_parent_dir };
              this.get_files(send);
          } else if (data.code == 22) {
            //同名 显示是否覆盖框
            this.$store.state.is_show_cover_files_box = true
            this.$msg.whiteerr('已存在同名目录或文件')
          } else if (data.code == 3 || data.code == 4) {
            this.$msg.whiteerr('所复制的文件不存在或已被删除')
          }
        })
      }

      
    },

    // 剪切 等于 移动
    shear_cloudlink_file(cur_copy_data){
      var send
      if (cur_copy_data.type == 'file') {
        send = {
          from_dir: cur_copy_data.parent_dir,
          to_dir: cur_copy_data.to_dir,
          filename: cur_copy_data.name,
        }
        move_cloud_file(send).then(res=>{
          var data = JSON.parse(res.data)
          if (data.code == 1) {
            this.$store.state.menus_paste_action = false
            this.$EventBus.$emit("getMyTableData");
          } else if (data.code == 2) {
            this.$msg.whiteerr('已存在同名目录或文件')
          } else if (data.code == 3) {
            this.$msg.whiteerr('该文件不存在或已被删除')
          }
        })
      } else {
        var send1 = {
          usertoken: this.usertoken,
          link_id: cur_copy_data.link_id,
          force: false,
          dir: cur_copy_data.dir
        }
        cloudlink_upddir(send1).then(res=>{
          var data = JSON.parse(res.data)
          if (data.code == 1) {
            this.$store.state.menus_paste_action = false
            this.$EventBus.$emit("getMyTableData");
          } else if (data.code == 22) {
            this.$msg.whiteerr('已存在同名目录或文件')
          }
        })
      }
      
    },

    // 暂停1s
    pause() {
      return new Promise((resolve) => {
        let timer = setTimeout(resolve, 1000);
        this.timerArr.push(timer);  // 创建了定时器，放到一个数组中，最后将其清除掉。
      });
    },

    //批量下载函数
    down_cloudlist_item(path,name) {
      var send = {
        parent_dir: path,
        filename: name
      }
      myCloudDown(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          if (this.isIE()) { // IE
              window.open(Global.httpsUrl + '/cloud/cloudapi/download?dl_id=' + data.dl_id, '_blank')
          } else {
            let a = document.createElement('a') // 创建a标签
            let e = document.createEvent('MouseEvents') // 创建鼠标事件对象
            e.initEvent('click', false, false) // 初始化事件对象
            a.href = Global.httpsUrl + '/cloud/cloudapi/download?dl_id=' + data.dl_id // 设置下载地址
            a.download = '' // 设置下载文件名
            a.dispatchEvent(e)
          }
        }
      })
    },

    //下载 link文件
    down_cloudlinklist_item(link_id) {
      var send = {
        link_id: link_id
      }
      myCloudLinkDown(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          if (this.isIE()) { // IE
              window.open(Global.httpsUrl + '/cloud/cloudapi/download?dl_id=' + data.dl_id, '_blank')
          } else {
            let a = document.createElement('a') // 创建a标签
            let e = document.createEvent('MouseEvents') // 创建鼠标事件对象
            e.initEvent('click', false, false) // 初始化事件对象
            a.href = Global.httpsUrl + '/cloud/cloudapi/download?dl_id=' + data.dl_id // 设置下载地址
            a.download = '' // 设置下载文件名
            a.dispatchEvent(e)
          }
        } else if (data.code == 2) {
          this.$msg.whiteerr('分享文件异常，无法下载')
        } else if (data.code == 3) {
          this.$msg.whiteerr('分享文件不存在，无法下载')
        } else if (data.code == 4) {
          this.$msg.whiteerr('分享用户不存在，无法下载')
        } else if (data.code == 5) {
          this.$msg.whiteerr('分享链接已过期，无法下载')
        } else if (data.code == 6) {
          this.$msg.whiteerr('无下载权限，无法下载')
        } else if (data.code == 7) {
          this.$msg.whiteerr('分享文件不存在，无法下载')
        }
      })
    },

    //显示批量删除确定框
    show_dels_box_my() {
      var _this = this
      this.$confirm("此操作将删除选中项, 是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "alert_del_btn",
        customClass: "cover_messageBox",
      })
        .then(() => {
          _this.$nextTick(()=>{
            _this.delfiles()
          })
        })
        .catch(() => {
        });
    },

    delfiles() {
      var items = this.$store.state.checkedItems;
      var del_list = []
      var del_links = []
      items.forEach((item) => {
        if (item.type == 'dir' || item.type == 'file') {
          var p = item.parent_dir + '/' + item.name
          p = p.replace('//', '/');
          del_list.push(p)
        } else {
          del_links.push(item.link_id)
        }
          
      })

      var send = {
        ps: JSON.stringify(del_list)
      }

      if (del_links.length != 0) {
        this.cloudlink_dels(del_links)
      }

      if (del_list.length == 0) {
        return
      }
      myCloudDels(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          if (del_links.length == 0) {
            var send1 = {p: this.$store.state.cur_parent_dir,};
            this.get_files(send1)
            this.get_Usage()
          }
        } else if (data.code == 99){
          this.login_expired_my()
        }
      })
    },


    cloudlink_dels(del_links) {
      var send = {
        usertoken: this.usertoken,
        link_ids: JSON.stringify(del_links)
      }
      cloudlink_del(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.login_expired_my();
        } else if (data.code == 1) {
          var send1 = {p: this.$store.state.cur_parent_dir,};
          this.get_files(send1)
          this.get_Usage();
        }
      });
    },



    isIE() {
      if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    cur_share_data() {
      return this.$store.state.curShareData;
    },
    tableData_my_drawings() {
      return this.$store.state.tableData_my_drawings;
    },
  },
};
</script>


<style lang="less" scoped>
.el-dropdown-menu-160 {
  width: 160px;
}

.el-dropdown-menu-160 li {
  height: 30px;
  line-height: 30px;
}

.el-empty_width100 {
  width: 100%;
}

.flex_center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
}

/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #4383f0 !important;
}

/deep/ .el-breadcrumb__inner {
  cursor: pointer;
}

.con-right-box {
  margin-top: 24px;
  margin-left: 30px;
  min-width: 0px;
  width: 100%;
  height: 100%;
}

.el-table .el-table__cell {
  text-align: center;
}

.cloud_path {
  font-size: 16px;
  color: #4383f0;
}

.lately_list_no {
  width: 100%;
  height: 100%;
}

.lately_list_no_box {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lately_list_no_box img {
  width: 110px;
  height: 110px;
}

.lately_list_no_th {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  padding-top: 10px;
  padding-bottom: 30px;
}

.lately_list_no_text {
  width: 140px;
  height: 42px;
  line-height: 42px;
  background-image: linear-gradient(129deg, #4383f0 0%, #50acf9 100%);
  border-radius: 4px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

#lately_list,#search_list {
  height: calc(100% - 180px);
  overflow: auto;
  min-width: 1050px;
}

.file-update-form {
  width: 17%;
  color: #767c85;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  font-size: 12px;
}

.file_collect {
  display: block;
}

.file_collect img {
  width: 20px;
  height: 20px;
}

#lately_list_head {
  min-width: 1050px;
}

.file-item {
  height: 54px;
  line-height: 54px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
}

.file-item-active {
  background-color: #f0f4fc;
}

.file-item:hover {
  background-color: #f0f4fc;
}

.lately_center {
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-name img {
  cursor: pointer;
}

.files-table {
  margin-right: 30px;
}

.lately_file_header {
  margin-right: 0;
}

.filedo-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 160px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 2px 12px 0 rgb(56, 56, 56, 0.2);
  font-size: 13px;
  color: #666;
}

.fdb-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-indent: 20%;
  cursor: pointer;
}

.fdb-item:hover {
  background-color: #f2f5fa;
}

.caozuo-box {
  height: 22px;
}

.my_dwg_top_btn {
  width: 90px;
  min-width: 90px;
  height: 34px;
  padding: 0;
}

.share-btn-default {
  ::v-deep .share-icon-default {
    background: url('@/assets/images/cloud/share_btn.png') center no-repeat;
    background-size: cover;
  }
  ::v-deep span {
    margin-left: 5px;
  }
}

.share-btn-default1 {
  ::v-deep .share-icon-default {
    background: url('@/assets/images/cloud/share_btn1.png') center no-repeat !important;
    background-size: cover !important;
  }
  ::v-deep span {
    margin-left: 5px;
  }
}

::v-deep .share-icon-default {
  font-size: 16px;
  background-size: cover;
  display: inline-block;
}

.share-btn-default:hover {
  ::v-deep .share-icon-default {
    background: url('@/assets/images/cloud/share_btn2.png') center no-repeat;
    background-size: cover;
  }
}

.share-btn-default1:hover {
  ::v-deep .share-icon-default {
    background: url('@/assets/images/cloud/share_btn2.png') center no-repeat !important;
    background-size: cover !important;
  } 
}

.share-btn-default2:hover {
  ::v-deep .share-icon-default {
    background: url('@/assets/images/cloud/share_btn.png') center no-repeat !important;
    background-size: cover !important;
  } 
}


::v-deep .share-icon-default:before {
  content: '替';
  font-size: 14px;
  visibility: hidden;
}

.cloud_file_header {
  height: auto;
  min-width: 1080px;
}

.cloud_file_header_checked_box {
  display: flex;
  width: 100%;
}

.line-cloud {
  position: absolute;
  bottom: -10px;
}

.file-item-no-hover:hover {
  background: none;
}

.serach_file_box_sm {
  width: 250px;
  height: 30px;
}

.serach_file_box {
  width: calc(100% - 70px);
  padding: 0 15px 25px 15px;
  height: 30px;
  display: flex;
}

:deep(.serach_file_box .el-input__inner),
:deep(.serach_file_box_sm .el-input__inner) {
  height: 30px;
  line-height: 30px;
}

:deep(.serach_file_box .el-input-group__append),
:deep(.serach_file_box_sm .el-input-group__append) {
  background-color: #4483F0;
  border:1px solid #4383f0;
  padding:0 15px;
}

:deep(.serach_file_box .el-input-group__append .el-button),
:deep(.serach_file_box_sm .el-input-group__append .el-button) {
  color:#fff;
  height: 30px;
  border: unset;
}

.file-name-serach {
  display: flex;
  align-items: center;
}

.file-name-serach img {
  margin-top: 0;
}

.file-serachname {
  display: flex;
  width: 100%;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height:40px;
}

.file-serachname span {
  width: 90%;
  display: inline-block;
  font-size: 14px;
  color: #333;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  height: 20px;
  line-height:20px;
}

.file-serachname .file-cloud-path {
  color: #999;
  font-size: 12px;
}

</style>
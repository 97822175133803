<template>
  <div class="con-right">
    <div class="con-right-box">
      <div
        class="files-table"
        v-loading="$store.state.tableData_loading_recently_open"
      >
        <div class="cloud_file_header lately_file_header">
          <div class="cloud_path">
            <span>最近打开</span>
          </div>
          <div class="cloud_right">
            <div class="cloud_right_add" id="shoucang_right_refresh" style="margin-left: 20px;" @click="refresh_lists_btn">
              <img src="@/assets/images/cloud/refresh.png" alt="">
              <span>刷新</span>
            </div>
            <div class="cloud_right_add" id="shoucang_right_sort" style="margin-left: 20px;position: relative;">
              <el-dropdown trigger="click" @command="dropdown_sort_btn">
                <span class="el-dropdown-link" style="display:flex;align-items: center;">
                  <img src="@/assets/images/cloud/sort.png" alt="">
                  <span>排序:{{$store.state.tableData_recently_sort.text}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                </span>
                <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-160">
                  <el-dropdown-item
                      :command="it"
                      v-for="(it, index) in dropdown_sort_items"
                      :key="index"
                      >{{ it.text  }}</el-dropdown-item
                    >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          
          <div class="line-cloud"></div>
        </div>

        <div class="lately_list_no" v-if="$store.state.tableData_recently_open.length == 0">
          <div class="lately_center">
            <div class="lately_list_no_box">
              <img src="@/assets/images/team/no_file.png" alt="" />
              <div class="lately_list_no_th">暂无文件</div>
            </div>
          </div>
        </div>
        <div id="lately_list_head" v-if="$store.state.tableData_recently_open.length != 0">
          <div class="file-item lately-item">
            <div class="file-name file-item-lately">
              <div>名称</div>
            </div>
            <div class="file-update-time list_header_color">日期</div>
            <div class="file-update-form list_header_color">类型</div>
            <div class="file-size list_header_color">大小</div>
            <div class="file-do caozuo-box"></div>
          </div>
        </div>
        <div id="lately_list" v-if="$store.state.tableData_recently_open.length != 0">
          <div
            class="file-item lately-item"
            :class="cur_click_item_index == i ? 'file-item-active' : ''"
            v-for="(item, i) in $store.state.tableData_recently_open"
            :key="i"
            @mouseenter="show_share_btn(i)"
            @mouseleave="hide_share_btn(i)"
            @click="is_show_share_click(i)"
            @contextmenu.prevent="show_right_menus_zj($event, item, i)"
          >
            <div class="file-name">
              <img
                :src="imgSrc1"
                @click="open_dwg_zj(item)"
                v-if="item.type == 'file'"
              />
              <img
                :src="item.expired ? imgSrc3 : imgSrc2"
                @click="open_dwg_zj(item)"
                v-if="item.type == 'link' || item.type == 'linkfile'"
              />
              <div
                class="file-name-word"
                :title="item.filename"
                style="color: rgb(61, 71, 87)"
                @click="
                  open_dwg_zj(item)
                "
              >
                {{ item.name }}
              </div>
            </div>
            <div class="file-update-time">{{ item.mtime }}</div>
            <div class="file-update-form" :title="item.share_from">
              {{ item.share_from }}
            </div>
            <div class="file-size">{{ item.sizeMB }}</div>
            <div class="caozuo-box">
              <div class="file-more">
                <el-dropdown trigger="click" @command="dropdown_item_btn_zj">
                  <span class="el-dropdown-link">
                    <img src="@/assets/images/cloud/more.png" />
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="el-dropdown-menu-160"
                  >
                    <el-dropdown-item
                      :command="item"
                      :type="it.type"
                      :index="i"
                      v-for="(it, index) in dropdown_items"
                      :key="index"
                      >{{ item.is_collect == 'y' && it.text == "收藏" ? '取消收藏' : it.text  }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <div
                class="file-collection file_collect"
                title="收藏当前图纸"
                @click="collect_cur_dwg_zj(i,item)
                "
                v-if="item.is_collect == 'y'"
              >
                <img
                  :src="item.is_collect == 'n' ? is_collect_0 : is_collect_1"
                />
              </div>
              <div
                class="file-collection file_collect"
                title="收藏当前图纸"
                @click="collect_cur_dwg_zj(i, item)"
                v-if="item.is_collect == 'n' && item.is_show_share"
              >
                <img
                  :src="item.is_collect == 'n' ? is_collect_0 : is_collect_1"
                />
              </div>
              <div
                class="file-share"
                title="生成网页链接发送给他人查看"
                v-show="item.is_show_share"
                @click="send_share_dwg_btn_zj(item)"
              >
                发送图纸
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_recent_file,
  myCloudCollection_yes,
  myCloudCollection_no,
  deleteRecent,
  openCloudfile,
  recent_add
} from "@/api/get_cloud";
import { get_share_code } from "@/api/share";
import utils from "@/utils/utils";

export default {
  name: "Recently_opened",
  data() {
    return {
      loading: true,
      tableData: [],
      multipleSelection: [],
      zuijin_list: [],
      is_collect_0: require("@/assets/images/cloud/collect.png"),
      is_collect_1: require("@/assets/images/cloud/icon_richeng_star.png"),
      file_guoqi_yes: require("@/assets/images/cloud/file_guoqi.png"),
      file_guoqi_no: require("@/assets/images/cloud/file.png"),
      dropdown_items: [
        { text: "打开", type: "open" },
        { text: "发送图纸", type: "share" },
        { text: "收藏", type: "collection" },
        { text: "删除", type: "del" },
      ],
      dropdown_sort_items: [
        { text: "名称", type: "name", },
        { text: "日期", type: "time" },
        { text: "类型", type: "type" },
        { text: "大小", type: "size" },
      ],
      usertoken: '',
      sortFiles: [],
      cur_cloud_sort_type: 'time',
      cur_cloud_sort_type_text: '日期',
      imgSrc1: require('@/assets/images/cloud/file_cloud.png'),
      imgSrc2: require('@/assets/images/cloud/file_form.png'),
      imgSrc3: require('@/assets/images/cloud/file_gg.png'),
      cur_click_item_index: -1
    };
  },

  created() {},

  mounted() {
    var USER_TOKEN = JSON.parse(localStorage.getItem("USERTOKEN"));
    if (USER_TOKEN != null) {
      this.usertoken = USER_TOKEN
    }
    this.getRecentFiles()
  },

  methods: {

    //获取最近打开列表
    getRecentFiles () {
      var send = {
        usertoken: this.usertoken,
        type: 'web'
      }
      this.$store.state.tableData_loading_recently_open = true;
      get_recent_file(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.login_expired_zj();
          return;
        } else if (data.code == 2) {
          this.login_expired_zj();
          return
        }
        this.set_tables_zj(data.data)
      })
    },

    // 渲染前排序
    set_tables_zj(data) {
      var newData = [];
      this.sortFiles = []
      this.sortFiles = data
      
      for (var i in this.sortFiles) {
        var item = this.sortFiles[i]
        item.is_show_share = false;
        item.sizeMB = utils.convert_size_MB(item.file_size);
        item.size = item.file_size
        item.mtime = utils.convert_date_day(item.insert_time)
        item.expired = false
        if (item.cloud_path) {
          item.is_share = 0
          item.name = item.cloud_path.substring(item.cloud_path.lastIndexOf("/")+1);
          item.type = 'file'
          item.share_from = "我的图纸";
        } else {
          item.is_share = 1
          item.name = item.link_name
          item.type = 'link'
          if (item.link_type == 'file') {
            item.type = 'linkfile'
          }

          // item.expired = item.share_remain_time <= 0 ? true : false
        }
        newData.push(item);
      }

      this.sortFiles = newData
      this.zj_files_sort()
      this.$store.state.tableData_recently_open = newData;
      this.$store.state.tableData_loading_recently_open = false;
    },

    //对最近列表进行排序
    zj_files_sort() {
      var cur_cloud_sort_type = this.$store.state.tableData_recently_sort.type
      if (cur_cloud_sort_type == 'time') {
        this.sortFiles.sort(utils.compareTime('insert_time','inverted'))
      } else if (cur_cloud_sort_type == 'size') {
        this.sortFiles.sort((a, b) => parseInt(b.size) - parseInt(a.size));
      } else if (cur_cloud_sort_type == 'name') {
        this.sortFiles.sort((a,b) => {
          return a.name.localeCompare(b.name, 'zh-Hans-CN', {sensitivity: 'accent'});
        })
      } else if (cur_cloud_sort_type == 'type') {
        this.sortFiles.sort(function (a) {
          if(a.type=="file")
            return 1;
          else
            return -1;
        });
      }
    },

    //排序操作
    dropdown_sort_btn(item) {
      this.$store.state.tableData_recently_sort = {
        text: item.text,
        type: item.type
      }
      
      this.set_tables_zj1(this.$store.state.tableData_recently_open)
    },

    // 渲染前排序
    set_tables_zj1(data) {
      this.sortFiles = []
      this.sortFiles = data
      this.zj_files_sort()
      this.cur_click_item_index = -1
      this.sortFiles.forEach((item)=>{ item.is_show_share = false })
      this.$store.state.tableData_recently_open = this.sortFiles;
    },

    //刷新
    refresh_lists_btn() {
      this.$store.state.tableData_loading_recently_open = true;
      this.getRecentFiles()
    },


    //登录过期，回到首页
    login_expired_zj() {
      this.$msg.whiteerr("登录信息已过期，请重新登陆");
      utils.del_localStorae();
      this.$router.push("/");
    },

    is_show_share_click(i) {
      this.cur_click_item_index = i
      this.$store.state.tableData_recently_open.forEach((item)=>{
        item.is_show_share = false
      })
      this.$store.state.tableData_recently_open[i].is_show_share = true;
      this.$store.commit('updateRecentlyData', this.$store.state.tableData_recently_open)
    },

    show_share_btn(i) {
      this.$store.state.tableData_recently_open[i].is_show_share = true;
      this.$store.commit('updateRecentlyData', this.$store.state.tableData_recently_open)
    },

    hide_share_btn(i) {
      if (this.cur_click_item_index == i) return
      this.$store.state.tableData_recently_open[i].is_show_share = false;
      this.$store.commit('updateRecentlyData', this.$store.state.tableData_recently_open)
    },

    open_dwg_zj(item) {
      if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法打开');return}
      if (item.type != 'file') {
        this.open_share_url(item)
        return
      }
      var send = {
        cloud_path: item.cloud_path,
        is_mobile: "0",
      };
      // const tempPage = window.open('', '_blank')
      console.log('openCloudfile desk')
      openCloudfile(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var f_id = data.f_id;
          let file_type = data.file_type
          if (file_type == '3d') {
            let pathInfo = this.$router.resolve({
              path: "/cdf",
              query: {
                file_id: f_id,
              },
            });
            this.open_file_recent_add (item.type, item.cloud_path)
            window.open(pathInfo.href, "_blank");
            // tempPage.location = pathInfo.href
          } else {
            let pathInfo = this.$router.resolve({
              path: "/dwg",
              query: {
                file_id: f_id,
              },
            });

            this.open_file_recent_add (item.type, item.cloud_path)
            console.log('openCloudfile desk')
            window.open(pathInfo.href, "_blank");
            // tempPage.location = pathInfo.href
          }
        } else if (res.code == 2) {
          this.$msg.whiteerr('文件不存在，可能已被删除')
        } else if (res.code == 3) {
          this.$msg.whiteerr('不支持文件类型')
        } else {
          this.$msg.whiteerr('未知原因，无法打开')
        }
      }).catch(()=>{
        this.$msg.whiteerr('网络开小差了，请稍后再试')
      });;
    },

    //打开文件，添加到最近
    open_file_recent_add (type, value) {
      var send;
      if (type == 'file') {
        var cloud_path = value
        send = {
          usertoken: this.usertoken,
          cloud_path: cloud_path
        }
      }
      recent_add(send).then(()=>{})
    },

    open_share_url(item) {
      if (item.file_type == '3d') {
        let pathInfo = this.$router.resolve({
        path: "/cdf",
        query: {
          share_id: item.share_id,
        },
      });
      window.open(pathInfo.href, "_blank");
      return
      }
      let pathInfo = this.$router.resolve({
        path: "/dwg",
        query: {
          share_id: item.share_id,
          link_id: item.link_id ? item.link_id : ''
        },
      });
      window.open(pathInfo.href, "_blank");
    },

    //列表操作
    dropdown_item_btn_zj(item, event) {
      var type = event.$attrs.type;
      var i = event.$attrs.index;
      if (type == "open") {
        //打开
        this.open_dwg_zj(item);
      } else if (type == 'share') {
        this.send_share_dwg_btn_zj(item)
      } else if (type == 'collection') {
        this.collect_cur_dwg_zj(i, item)
      } else if (type == "del") {
        //删除
        this.del_item_zj (item,i)
      }
    },

     // 右键显示菜单
    show_right_menus_zj(event,item, i) {
      this.cur_click_item = item
      this.cur_click_item_index = i
      this.is_show_share_click(i)
      var that = this
      this.$contextmenu({
        items: [
          {
            label: "打开",
            onClick: () => {
              that.open_dwg_zj(item);
            }
          },
          { label: "发送图纸",
            disabled: item.type != 'dir' ? false : true,
            onClick: () => {
              that.send_share_dwg_btn_zj(item)
            }
          },
          { label: item.is_collect == 'n' ? "收藏" : "取消收藏", disabled: item.type != 'dir' ? false : true, onClick: () => {
              that.collect_cur_dwg_zj(i, item);
            }
          },
          { label: "删除",
          onClick: () => {
              that.del_item_zj(item, i);
            }
          },
        ],
        event,
        //x: event.clientX,
        //y: event.clientY,
        customClass: "class-a",
        zIndex: 3,
        minWidth: 160
      });
      return false;
    },

    del_item_zj(item,i){
      var sendData = {
          usertoken: this.usertoken,
          recent_id: item.recent_id,
        };
        deleteRecent(sendData).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            this.$msg.whiteerr("登录已失效，请重新登录");
            return;
          } else if (data.code == 1) {
            var items = this.$store.state.tableData_recently_open;
            items.splice(i,1);
            this.$msg.white("删除成功");
          }
        });
    },

    //最近打开 收藏
    collect_cur_dwg_zj(i, item) {
      if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法操作');return}
      var { link_id, is_share, cloud_path, is_collect }  = item
      var send;
      if (is_share == 1){ 
        send = {
          usertoken: this.usertoken,
          link_id: link_id,
        }
      } else {
        send = {
          usertoken: this.usertoken,
          cloud_path: cloud_path,
        }
      }

      if (is_collect == 'n') {
        myCloudCollection_yes(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            this.login_expired_zj();
          } else if (data.code == 1) {
            this.$store.state.tableData_recently_open[i].is_collect = 'y';
            this.$msg.white('已成功收藏')
          }else if (data.code == 7) {
            this.$msg.whiteerr('文件不存在，可能已被删除')
          }
        });
      } else {
        myCloudCollection_no(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 99) {
            this.login_expired_zj();
          } else if (data.code == 1) {
            this.$store.state.tableData_recently_open[i].is_collect = 'n';
            this.$msg.white('已取消收藏')
          } else if (data.code == 7) {
            this.$msg.whiteerr('文件不存在，可能已被删除')
          }
        });
      }
    },

    send_share_dwg_btn_zj(item) {
      if (item.expired) {this.$msg.whiteerr('文件分享链接已过期，无法分享');return}
      this.$store.state.share_from = 'myzj'
      this.$store.state.is_show_share_box = true;
      this.$store.state.cur_share_data = item;
      this.$store.commit("updateCurShareData_cloud", item);
      this.$EventBus.$emit("showShareBox");
      // this.getShareQcode_zj(item);
    },

    //获取分享地址
    getShareQcode_zj(item) {
      // var type = this.$store.state.cur_show_action;
      this.$store.state.share_box_url = "正在努力获取中...";
      if (item.is_share == 1) {
        this.$store.state.share_box_url = item.share_url;
        return
      }
      var share_box_p = item.cloud_path
      var send = {
        type: 0,
        p: share_box_p,
      };
      
      get_share_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          this.$store.state.share_box_url = url;
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已被删除')
        } else if (data.code == 99) {
          this.login_expired_zj();
        }
      });
    },
  },
  computed: {
    cur_share_data() {
      return this.$store.state.curShareData;
    },
  },
};
</script>


<style lang="less" scoped>
.el-dropdown-menu-160 {
  width: 160px;
}

.el-dropdown-menu-160 li {
  height: 30px;
  line-height: 30px;
}

.con-right-box {
  margin-top: 24px;
  margin-left: 30px;
  min-width: 0px;
  width: 100%;
  height: 100%;
}

.el-table .el-table__cell {
  text-align: center;
}

.cloud_path {
  font-size: 16px;
  color: #4383f0;
}

.lately_list_no {
  display: block;
  height: 100%;
}

.lately_list_no_box {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lately_list_no_box img {
  width: 110px;
  height: 110px;
}

.lately_list_no_th {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  padding-top: 10px;
  padding-bottom: 30px;
}

.lately_list_no_text {
  width: 140px;
  height: 42px;
  line-height: 42px;
  background-image: linear-gradient(129deg, #4383f0 0%, #50acf9 100%);
  border-radius: 4px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

#lately_list {
  height: calc(100% - 130px);
  overflow: auto;
  min-width: 836px;
}

.file-update-form {
  width: 17%;
  color: #767c85;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  font-size: 12px;
}

.file_collect {
  display: block;
}

.file_collect img {
  width: 20px;
  height: 20px;
}

#lately_list_head {
  min-width: 836px;
}

.file-item {
  height: 54px;
  line-height: 54px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
}

.file-item-active {
  background-color: #f0f4fc;
}

.file-item:hover {
  background-color: #f0f4fc;
}

.lately_center {
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-name img {
  cursor: pointer;
}

.files-table {
  margin-right: 30px;
}

.lately_file_header {
  margin-right: 0;
}

.caozuo-box {
  height: 22px;
}
</style>
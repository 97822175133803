<template>
  <div class="con-left">
    <div class="item-type-1">
      <input
        id="input_upload"
        style="
          position: absolute;
          opacity: 0;
          width: 1px;
          height: 1px;
          cursor: pointer;
        "
        type="file"
        multiple="false"
        name="input_upload"
        accept=".dwg, .dxf, .stp, .step, .stpz,.igs, .iges,.ipt, .iam,.sldprt, .sldasm,.model, .session, .exp, .catpart, .catproduct, .3dxml, .cgr, .prt, .asm, .par, .asm, .psm,.prt, .jt, .j_t,.stl,.rvt,.3dm,.sat, .asat, .sab, .asab,.x_t, .x_b,.rfa, .ifc"
        ref="fileRef"
        @change="handleChange_upload"
      />
      <input
        type="file"
        id="inputUploadDir"
        name="files"
        webkitdirectory
        multiple
        style="
          position: absolute;
          opacity: 0;
          width: 1px;
          height: 1px;
          cursor: pointer;
        "
        ref="dirRef"
        @change="handleChange_uploadDir"
      />

      <el-dropdown class="upload_all_btn_drop">
        <div id="upload_all_btn" class="upload-btn" @click="aside_upload_btn">
          <div class="upload-btn-img">
              <img src="@/assets/images/common/open_dir_btn.png" />
            </div>
          <div class="upload-btn-word">上传图纸</div>
          <!-- <el-upload
            class="upload-demo1"
            :action="apiUrl"
            :multiple="false"
            :show-file-list="false"
            accept=".dwg, .dxf, .prt, .step, .3dm, .sldprt, .rfa, .sat, .par, .ipt, .stp, .ifc, .igs, .iges, .stl, .rvt"
            ref="uploadFile"
            :on-success="uploadFileSuccess"
            :on-error="uploadFileError"
            :before-upload="beforeUpload"
            :data="{
              parent_dir: $store.state.cur_parent_dir,
              uid: this.uid,
            }"
          >
            <div class="upload-btn-img">
              <img src="@/assets/images/common/open_dir_btn.png" />
            </div>
            <div class="upload-btn-word">上传图纸</div>
          </el-upload> -->
        </div>

        <el-dropdown-menu slot="dropdown">
          <div class="up_all_btn_box">
            <div class="up_all_btn_flex">
              <div class="up_all_btn_item">
                <el-upload
                  class="upload-demo"
                  :action="apiUrl"
                  :multiple="false"
                  :with-credentials='true'
                  :show-file-list="false"
                  accept=".dwg, .dxf, .stp, .step, .stpz,.igs, .iges,.ipt, .iam,.sldprt, .sldasm,.model, .session, .exp, .catpart, .catproduct, .3dxml, .cgr, .prt, .asm, .par, .asm, .psm,.prt, .jt, .j_t,.stl,.rvt,.3dm,.sat, .asat, .sab, .asab,.x_t, .x_b,.rfa, .ifc"
                  ref="uploadFile"
                  :on-success="uploadFileSuccess"
                  :before-upload="beforeUpload"
                  :data="{
                    parent_dir: $store.state.cur_parent_dir,
                    uid: this.uid,
                  }"
                >
                  <img src="@/assets/images/common/open_dwg_img.png" alt="" />
                  <span>单张图纸</span>
                </el-upload>
              </div>
              <div class="up_all_btn_item_line"></div>
              <div
                class="up_all_btn_item"
                id="upload_dir_btn"
                @click="uploadDir_btn"
              >
                <img src="@/assets/images/common/open_dir_img.png" alt="" />
                <span>文件夹</span>
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>

      <div
        id="create_newdwg_btn"
        class="upload-btn"
        style="margin-top: 10px"
        @click="$store.state.is_show_create_dwg_box = true, $store.state.cur_checked_path = $store.state.cur_parent_dir"
      >
        <div class="upload-btn-img">
          <img src="@/assets/images/common/add_dwg_img.png" />
        </div>
        <div class="upload-btn-word">新建图纸</div>
      </div>
    </div>
    <div class="con-items">
      <div class="item-type item-type-2">
        <div
          class="item"
          :class="$store.state.fullPath == 'recently_opened' ? 'item-active' : ''"
          id="new-files"
          @click="aside_click_jump('recently_opened')"
        >
          <div class="item-img">
            <img src="@/assets/images/cloud/zuijin.png" />
          </div>
          <div class="item-word">最近打开</div>
        </div>
        <div
          id="all_files"
          class="item"
          :class="$store.state.fullPath == 'my_drawings' ? 'item-active' : ''"
          @click="aside_click_jump('my_drawings')"
        >
          <div class="item-img">
            <img src="@/assets/images/cloud/quanbu.png" />
          </div>
          <div class="item-word">CAD云盘</div>
        </div>
        <div
          class="item"
          :class="$store.state.fullPath == 'my_collection' ? 'item-active' : ''"
          @click="aside_click_jump('my_collection')"
        >
          <div class="item-img">
            <img src="@/assets/images/cloud/shoucang_item.png" />
          </div>
          <div class="item-word">我的云收藏</div>
        </div>
        <!-- <div class="item-empty"></div> -->
        <div
          id="my-team"
          :class="$store.state.fullPath == 'my_team' ? 'item-active' : ''"
          class="item my-team"
          @click="aside_click_jump('my_team')"
        >
          <div class="item-img">
            <img src="@/assets/images/cloud/wodetuandui.png" />
          </div>
          <div class="item-word item-word-myteam">我的群</div>
          <div class="item-img-right">
            <img src="@/assets/images/cloud/myteam_hot.png" />
          </div>
        </div>
      </div>
      <div class="line3"></div>
      <div class="item-type item-type-3">
        <div class="item" data-type="cad2pdf"
          :class="$store.state.fullPath == 'cad_pdf' ? 'item-active' : ''"
          @click="aside_click_jump('cad_pdf')">
          <div class="item-img">
            <img src="@/assets/images/cloud/cad2pdf.png" />
          </div>
          <div class="item-word">CAD转PDF</div>
        </div>
        <div class="item" data-type="cad2img"
        :class="$store.state.fullPath == 'cad_img' ? 'item-active' : ''"
          @click="aside_click_jump('cad_img')">
          <div class="item-img">
            <img src="@/assets/images/cloud/cad2img.png" />
          </div>
          <div class="item-word">CAD转图片</div>
        </div>
        <div class="item" data-type="cad2ver"
        :class="$store.state.fullPath == 'cad_ver' ? 'item-active' : ''"
          @click="aside_click_jump('cad_ver')">
          <div class="item-img">
            <img src="@/assets/images/cloud/cad2ver.png" />
          </div>
          <div class="item-word">CAD转版本</div>
        </div>
        <div class="item" data-type="tz"
        :class="$store.state.fullPath == 'cad_tz' ? 'item-active' : ''"
          @click="aside_click_jump('cad_tz')">
          <div class="item-img"><img src="@/assets/images/cloud/tz.png" /></div>
          <div class="item-word">天正转换</div>
        </div>
        <!-- <div class="item" data-type="pdf2cad">
                    <div class="item-img"><img src="@/assets/images/cloud/huishouzhan.png"></div>
                    <div class="item-word">PDF转CAD</div>
                </div> -->
      </div>
    </div>

    <div class="cloud-guanggao" @click="$store.state.cloud_guanggao_action = true, $store.state.mask = true">
      <div class="cloud-guanggao-top">
        <div class="cloud-guanggao-top-left">进入群协作办公</div>
        <div class="cloud-guanggao-top-right"></div>
      </div>
      <div class="cloud-guanggao-btn">立即创建</div>
      <div class="cloud-guanggao-bg"></div>
    </div>
    <div class="cloud-size">
      <!-- <div id="cloud_progress">
        <div id="cur_cloud_progress"></div>
      </div> -->
      <el-progress
        :show-text="false"
        :percentage="$store.state.percentage > 100 ? 100 : $store.state.percentage"
        :color="$store.state.customColors"
      ></el-progress>
      <div class="cloud-size-word">
        <div class="csw-l">
          <span class="cloud-usage">{{ $store.state.usage }}</span
          >/<span class="cloud-total">{{ $store.state.total }}</span>
        </div>
        <!-- <div id="extend_cloud">扩充</div> -->
      </div>
    </div>
    <div class="line2" style="height: 100%"></div>
  </div>
</template>

<script>
import { upload_dwg } from "@/api/get_cloud";
import { getUsage } from "@/api/user";
import utils from "@/utils/utils";
// import Global from "@/views/dwg/global"
export default {
  name: "AppAside",
  components: {},
  props: ["is-collapse", "user_uid"],
  data() {
    return {
      apiUrl: process.env.VUE_APP_BASE_API + "/cloud/cloudapi/upload_cloudfile",
      cur_path: "cloud",
      show_up_all_btn_box: false, //是否显示打开图纸弹出
      upload_file: null,
      uid: "",
      waitFileList: [],
    };
  },
  watch: {},
  created() {
    this.$store.state.fullPath = this.$route.path.split("/")[1];
    this.uid = this.$props.user_uid;
  },
  mounted() {
    var that = this;

    // 监听其他组件调用上传文件
    this.$EventBus.$off("uploadFile");
    this.$EventBus.$on("uploadFile", function () {
      that.upload_btn();
    });

    // 监听其他组件调用上传文件夹
    this.$EventBus.$off("uploadDir");
    this.$EventBus.$on("uploadDir", function () {
      that.uploadDir_btn();
    });

    // 监听切换左边tab
    this.$EventBus.$off("checkLeftMenu");
    this.$EventBus.$on("checkLeftMenu", function (data) {
      that.aside_click_jump(data);
    });
  },

  computed: {
    fullPath () {
      return this.$store.state.fullPath
    }
  },

  methods: {
    go_to_dwg() {
      let routeData = this.$router.resolve({
        path: "/dwg",
        query: { f_id: "222222222", type: this.created_type },
      });
      window.open(routeData.href, "_blank");
    },

    aside_click_jump(url) {
      if (this.$store.state.fullPath == url) {
        return;
      }
      this.$store.state.fullPath = url;
      this.$store.commit('uploadFullPath', url)
      this.$router.push({ name: url });
    },

    show_upload_box() {
      this.show_up_all_btn_box = true;
    },

    hide_upload_box() {
      this.show_up_all_btn_box = false;
    },

    //上传是否覆盖确认
    show_is_cover(file, __file) {
      var _this = this;
      this.$confirm(
        "当前位置存在同名文件，点击上传将会保留两个文件，确认要上传么？",
        "提示",
        {
          confirmButtonText: "上传",
          cancelButtonText: "取消",
          customClass: "cover_messageBox",
        }
      )
        .then(() => {
          _this.$nextTick(() => {
            // _this.$refs.uploadFile.$children[0].post(file)
            _this.upload_cover_sumbit(file, __file);
          });
        })
        .catch(() => {
          _this.$refs.uploadFile.handleRemove(file);
          var waitFileList = _this.$store.state.waitFileList;
          var newArr = [];
          waitFileList.map((item) => {
            if (item.uid == __file.uid) {
              item = undefined;
            }
            if (item != undefined) {
              newArr.push(item);
            }
          });
          _this.$store.state.waitFileList = newArr;
          this.$store.commit(
            "updateWaitFileList",
            this.$store.state.waitFileList
          );
          this.$refs.fileRef.value = "";
        });
    },

    //上传文件请求
    upload_cover_sumbit(file, __file) {
      //改名
      var newFileName = this.check_filename(file.name);

      var renameReportFile = new File([file], newFileName, {
        type: file.type,
      });
      __file.name = newFileName;

      const fd = new FormData();
      fd.set("file", renameReportFile);
      fd.set("uid", this.uid);
      fd.set("parent_dir", this.$store.state.cur_parent_dir);

      upload_dwg(fd).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.upload_success(data, __file);
        } else if (data.code == 3) {
          this.upload_error(data, __file);
        } else if (data.code == 99) {
          this.upload_error(data, __file);
        }
        this.$refs.fileRef.value = "";
      });
    },
    //上传文件成功处理
    upload_success(data, file) {
      var oldFiles = this.$store.state.waitFileList;
      oldFiles.map((it) => {
        if (it.uid == file.uid) {
          it.f_id = data.file_id;
          it.name = file.name;
          it.status = "success";
          it.statusText = "成功";
          it.statusImg = require("@/assets/images/cloud/collection.png");
        } else {
          file.f_id = data.file_id;
          file.status = "success";
          file.statusText = "成功";
          file.statusImg = require("@/assets/images/cloud/collection.png");
        }
      });
      this.get_Usage();
      this.$msg.white('上传成功')
      this.$store.commit("updateWaitFileList", this.$store.state.waitFileList);
      var cur_path = this.$route.fullPath;
      if (
        cur_path == "/my_drawings" ||
        cur_path == "/recently_opened" ||
        cur_path == "/my_collection"
      ) {
        this.$EventBus.$emit("getMyTableData");
      }
      this.$refs.uploadFile.clearFiles();
    },
    //上传文件错误处理
    upload_error(data, file) {
      var oldFiles = this.$store.state.waitFileList;
      oldFiles.map((it) => {
        if (it.uid == file.uid) {
          it.status = "error";
          it.statusText = "失败";
          it.name = file.name;
          it.statusImg = require("@/assets/images/cloud/upload_err.png");
        } else {
          file.statusText = "失败";
          file.status = "error";
          file.statusImg = require("@/assets/images/cloud/upload_err.png");
        }
      });
      if (data.code == 3) {
        this.$msg.whiteerr("内存已满，请清理云盘空间");
        this.show_cloud_size_overflow();
      } if (data.code == 4) {
        this.$msg.whiteerr("上传文件类型无效，无法上传")
      } else if (data.code == 99) {
        this.$msg.whiteerr("上传失败，登录信息已过期，请重新登录");
      }
      this.$store.commit("updateWaitFileList", this.$store.state.waitFileList);
      this.$refs.uploadFile.clearFiles();
    },

    //上传前检测查看是否有重复数据
    beforeUpload(file) {
      var files = this.$store.state.tableData_my_drawings;
      this.$store.state.is_show_up_history = true;
      this.$store.state.is_show_up_all = true;
      var __file = JSON.parse(JSON.stringify(file));
      __file.path = this.$store.state.cur_parent_dir;
      __file.statusText = "";
      __file.name = file.name;
      __file.type = 'file';
      if (this.is_has_m(file.size) == -1) {
        __file.size = utils.convert_size_MB(file.size);
      }
      __file.statusImg = require("@/assets/images/cloud/updata_loading.png");
      this.$store.state.waitFileList = [
        ...[__file],
        ...this.$store.state.waitFileList,
      ];

      if (files.length != 0) {
        for (let i = 0; i < files.length; i++) {
          var item = files[i];
          if (item.name == file.name) {
            this.show_is_cover(file, __file);
            return false;
          }
        }
      } else {
        // this.$store.state.waitFileList = [
        //   ...this.$store.state.waitFileList,
        //   ...[__file],
        // ];
      }
    },

    //上传文件成功回调 第三参数fileList先去掉
    uploadFileSuccess(response, file) {
      var oldFiles = this.$store.state.waitFileList;
      if (response.code == 1) {
        file.path = this.$store.state.cur_parent_dir;
        file.f_id = response.file_id;
        file.statusText = "成功";
        file.statusImg = require("@/assets/images/cloud/collection.png");
      } else if (response.code == 3) {
        this.$msg.whiteerr("内存已满，请清理云盘空间");
        file.statusText = "失败";
        file.statusImg = require("@/assets/images/cloud/upload_err.png");
        this.show_cloud_size_overflow();
      } else {
        file.statusText = "失败";
        file.statusImg = require("@/assets/images/cloud/upload_err.png");
      }

      oldFiles.map((it) => {
        if (it.uid == file.uid) {
          it.f_id = response.file_id;
          it.status = file.status;
          it.statusText = file.statusText;
          it.statusImg = file.statusImg;
        }
      });

      this.$store.commit("updateWaitFileList", this.$store.state.waitFileList);
      var cur_path = this.$route.fullPath;
      if (
        cur_path == "/my_drawings" ||
        cur_path == "/recently_opened" ||
        cur_path == "/my_collection"
      ) {
        this.$EventBus.$emit("getMyTableData");
      }
      this.$refs.uploadFile.clearFiles();
      this.get_Usage();
    },

    //上传文件失败回调
    uploadFileError(res, file) {
      var oldFiles = this.$store.state.waitFileList;
      oldFiles.map((it) => {
        if (it.uid == file.uid) {
          it.status = "error";
          it.statusText = "失败";
          it.name = file.name;
          it.statusImg = require("@/assets/images/cloud/upload_err.png");
        } else {
          file.statusText = "失败";
          file.status = "error";
          file.statusImg = require("@/assets/images/cloud/upload_err.png");
        }
      });
      this.$store.commit("updateWaitFileList", this.$store.state.waitFileList);
    },

    //内存已满
    show_cloud_size_overflow() {
      var _this = this;

      this.$alert(
        `<div class="memory_full_body"><div class="cover_body_text">空间不足 <span style="margin-left:10px" class="cloud-usage">${this.$store.state.usage}</span>/<span class="cloud-total">${this.$store.state.total}</span></div><div class="memory_full_line" style="width:${this.$store.state.percentage}%;"></div><div class="memory_full_text">云盘空间不足，请先扩充空间或清理云盘后重试</div></div>`,
        "上传失败",
        {
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "扩充空间",
          cancelButtonText: "清理",
          customClass: "cover_messageBox",
        }
      )
        .then(() => {
          _this.$nextTick(() => {
            this.$store.state.is_show_pay = true;
            this.$store.state.mask = true;
          });
        })
        .catch(() => {
          this.$msg.white("赶快去我的图纸清理一下吧")
        });
    },

    // 判断是否已经转换为M
    is_has_m(size) {
      var aa = (size - 0).toString().indexOf("M");
      return aa;
    },

    get_Usage() {
      getUsage().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          return;
        } else {
          this.$store.state.usage = utils.convert_size_MB(data.usage);
          this.$store.state.total = utils.convert_size_MB(data.total);
          this.$store.state.percentage =
            ((data.usage / data.total) * 100).toFixed(0) - 0;
        }
      });
    },

    // 多次重名
    check_filename(fileName) {
      // file_lists 当前文件列表
      var idX = 1;
      var is = true;
      var newFileName = "";
      var fistName = utils.getFileName(fileName)
      var lastName = utils.getExtensiona(fileName)
      while (is) {
        newFileName = fistName + "(" + idX + ")" + lastName;
        is = this.is_exist_name(newFileName);
        idX++;
        if (idX == 50) {
          is = false;
        }
      }
      return newFileName;
    },

    is_exist_name(fileName) {
      // if (this.$route.fullPath == "/my_drawings") {
      var is_exist_name = false;
      this.$store.state.tableData_my_drawings.forEach((item) => {
        if (fileName == item.name) {
          is_exist_name = true;
        }
      });
      return is_exist_name;
      // } else {
      // var is_exist_name = false;
      // cur_team_files_data.forEach((item) => {
      //   if (fileName == item.name) {
      //     is_exist_name = true;
      //   }
      // });
      // return is_exist_name;
      // }
    },

    //是否同名文件夹
    is_dir_name_xiangtong(upload_dir_name) {
      var file_lists = this.$store.state.tableData_my_drawings;
      var is_upload_dir_name = false;
      file_lists.forEach((item) => {
        if (item.type == "dir" && item.name == upload_dir_name) {
          is_upload_dir_name = true;
        }
      });
      return is_upload_dir_name;
    },

    //侧边栏上传图纸按钮
    aside_upload_btn() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },

    //我的图纸里上传
    upload_btn() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
    },

    handleChange_upload(e) {
      // if (e.target.files.length != 1) {
      //   this.$refs.fileRef.value = "";
      //   this.$message.info("目前仅支持单文件上传,您可以选择上传文件夹哦~");
      //   return;
      // }
      // var files = this.$store.state.tableData_my_drawings;
      var waitting_files = e.target.files;
      this.$store.state.is_show_up_history = true;
      this.$store.state.is_show_up_all = true;

      for (let j = 0; j < waitting_files.length; j++) {
        var __file = JSON.parse(JSON.stringify(waitting_files[j]));;
        
        __file.path = this.$store.state.cur_parent_dir;
        __file.statusText = "";
        __file.name = waitting_files[j].name;
        __file.uid = this.randomString(10);
        __file.type = 'file';
        __file.size = utils.convert_size_MB(waitting_files[j].size)
        __file.statusImg = require("@/assets/images/cloud/updata_loading.png");
        this.$store.state.waitFileList = [
          ...[__file],
          ...this.$store.state.waitFileList,
        ];
        this.uploadDwg(waitting_files[j], __file);
      }
      
      // console.log(waitting_files)
      
      return
      this.$store.state.is_show_up_history = true;
      this.$store.state.is_show_up_all = true;

      var __file = JSON.parse(JSON.stringify(file));
      __file.path = this.$store.state.cur_parent_dir;
      __file.statusText = "";
      __file.name = file.name;
      __file.uid = this.randomString(10);
      __file.type = 'file';
      if (this.is_has_m(file.size) == -1) {
        __file.size = utils.convert_size_MB(file.size);
      }
      __file.statusImg = require("@/assets/images/cloud/updata_loading.png");
      this.$store.state.waitFileList = [
        ...[__file],
        ...this.$store.state.waitFileList,
      ];
      if (files.length != 0) {
        for (let i = 0; i < files.length; i++) {
          var item = files[i];
          if (item.name == file.name) {
            this.show_is_cover(file, __file);
            return;
          }
        }
        this.uploadDwg(file, __file);
      } else {
        this.uploadDwg(file, __file);
      }
    },

    uploadDwg(file, __file) {
      var ss = this.is_cloudfile_name(file.name)
      var newFileName = ss ? this.check_cloudfilename(file.name) : file.name
      const fd = new FormData();
      var renameReportFile = new File([file], newFileName, { type: file.type });
      fd.append("file", renameReportFile);
      __file.name = newFileName
      fd.set("uid", this.uid);
      fd.set("parent_dir", this.$store.state.cur_parent_dir);
      upload_dwg(fd).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.upload_success(data, __file);
        } else if (data.code == 3) {
          this.upload_error(data, __file);
        } else if (data.code == 4) {
          this.upload_error(data, __file);
        } else if (data.code == 99) {
          this.upload_error(data, __file);
        }
        this.$refs.fileRef.value = "";
      }).catch((err)=>{
        // console.log('err', err)
        this.upload_error({}, __file);
      });
    },

    randomString(len) {
      len = len || 32;
      let timestamp = new Date().getTime();
      /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
      let $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      let maxPos = $chars.length;
      let randomStr = "";
      for (let i = 0; i < len; i++) {
        randomStr += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return randomStr + timestamp;
    },

    uploadDir_btn() {
      this.$refs.dirRef.dispatchEvent(new MouseEvent("click"));
    },

    //上传文件夹
    handleChange_uploadDir(e) {
      if (this.$store.state.is_upload_dir_ing) {
        this.$msg.whiteerr("已有任务正在上传中，请稍后")
        return
      }
      var files = e.target.files;
      if (files.length == 0) {
        this.$refs.dirRef.value = "";
        this.$store.state.is_upload_dir_ing = false;
        this.$msg.whiteerr("文件夹为空，无法上传")
        return
      }
      var upload_dir_name1;
      var upload_dir_name = files[0].webkitRelativePath; //拿到上传需要的名字
      this.$store.state.is_show_up_history = true;
      this.$store.state.is_show_up_all = true;
      if (upload_dir_name.length > 0) {
        //包含.dwg 截取 第一个 / 之前的字符串
        upload_dir_name1 = upload_dir_name.substring(
          0,
          upload_dir_name.indexOf("/")
        );
      }
      //查询是否同名文件夹
      var is_upload_dir_name = this.is_dir_name_xiangtong(upload_dir_name1);
      var dirUid = this.randomString(10)
      if (is_upload_dir_name) {
        //同名处理  弹窗 确定
        console.log("有同名文件夹")
      }

      var newFiles = []
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.name != ".DS_Store") {
          //过滤mac下面的 .DS_Store文件
          // 文件夹内文件后缀名包含在规定之内
          var hzm = utils.getExtensiona(file.name)
          if (this.$store.state.upload_file_types.indexOf(hzm) > -1) {
            newFiles.push(file)
          }
        }
      }
      //不同名 直接上传
      //遍历全部文件
      for (let j = 0, len = newFiles.length; j < len; j++) {
        //上传单个文件
       
        var pos = newFiles[j].webkitRelativePath.lastIndexOf("/"); //'/所在的最后位置'
        var upload_dir_path = newFiles[j].webkitRelativePath.substr(0, pos);
        this.uploadDirFile(
          newFiles[j],
          upload_dir_path,
          is_upload_dir_name,
          upload_dir_name1,
          j,
          len - 1,
          dirUid
        ); //上传到oss
          
      }
    },

    uploadDirFile(file, filePath, is_upload_dir_name,upload_dir_name1, j, len, dirUid) {
      var formData = new FormData();
      formData.append("uid", this.uid);
      formData.append("file", file);
      var dir_path__;
      var dir_path__name;
      var first_dir_path_name;
      var cloud_path_cur = this.$store.state.cur_parent_dir
      if (is_upload_dir_name) {
        // if (j == 0) {
          first_dir_path_name = this.check_dirname(filePath);
        // }
        dir_path__name = filePath.replace(
          upload_dir_name1,
          first_dir_path_name
        );
        dir_path__ =
          cloud_path_cur == "/"
            ? "/" + dir_path__name
            : cloud_path_cur + "/" + dir_path__name;
        formData.append("parent_dir", dir_path__);
      } else {
        dir_path__name = filePath;
        dir_path__ =
          cloud_path_cur == "/"
            ? "/" + dir_path__name
            : cloud_path_cur + "/" + dir_path__name;
        formData.append("parent_dir", dir_path__);
      }
      this.$store.state.is_upload_dir_ing = true;
      if (j == 0) {
        this.push_upload_container_first(dir_path__, dir_path__name, dirUid);
      }

      upload_dwg(formData).then((res) => {
        var data = JSON.parse(res.data);
        var neicun_man = this.$store.state.neicun_man;
        var action = false;
        if (data.code == 1) {
          action = true;
          neicun_man = false;
        } else if (data.code == 99) {
          this.$store.state.is_upload_dir_ing = false;
          return;
        }
        if (data.code == 3) {
          neicun_man = true;
          console.error("内存满了");
          setTimeout(() => {
            if (j == len) {
              this.get_Usage()
              this.$msg.whiteerr('内存已满，请清理云盘空间')
              this.$store.state.is_upload_dir_ing = false;
              this.show_cloud_size_overflow();
              this.$refs.dirRef.value = "";
              this.push_upload_container_last(
                dir_path__,
                dir_path__name,
                neicun_man,
                dirUid,
              );
            }
          }, 200);
          return;
        }

        console.log(action, j, len)
        if (action && j == len) {
          this.get_Usage()
          // this.show_cloud_size_overflow();
          this.$refs.dirRef.value = "";
          this.$store.state.is_upload_dir_ing = false;
          this.push_upload_container_last(dir_path__, dir_path__name, neicun_man, dirUid)
        }
      }).catch(()=>{
        this.$refs.dirRef.value = "";
        this.$store.state.is_upload_dir_ing = false;
        this.push_upload_container_last(dir_path__, dir_path__name, false, dirUid, 'error')
      });

      // error: function (err) {
      //     is_upload_dir_ing = false
      // }
    },


    //上传文件追加
    push_upload_container_first(dirPath, dir_name, dir_uid) {
      var dir = {
        name: dir_name,
        path: dirPath,
        type: 'dir',
        status: '',
        statusText: '',
        statusImg: require("@/assets/images/cloud/updata_loading.png"),
        uid: dir_uid
      }
      var waitFileList = this.$store.state.waitFileList
      if (waitFileList.length == 0) {
        waitFileList.push(dir)
      } else {
        waitFileList.unshift(dir)
      }

      // this.$store.commit("updateWaitFileList", waitFileList);
    },

    push_upload_container_last(dirPath, dir_name, neicun_man, dir_uid, action='success') {
      var waitLists = this.$store.state.waitFileList
      waitLists.forEach((item)=>{
        if (item.uid == dir_uid) {
          // item.path = dirPath
          if (action == 'success') {
            item.status =  'success'
            item.statusText = '成功'
            item.statusImg = require("@/assets/images/cloud/collection.png");
          } else {
            item.status =  'error'
            item.statusText = '失败'
            item.statusImg = require("@/assets/images/cloud/upload_err.png");
          }
          
        }
      })
      if (action == 'error') {
        return
      }
      var cur_path = this.$route.fullPath
      
      if (
        cur_path == "/my_drawings" ||
        cur_path == "/recently_opened" ||
        cur_path == "/my_collection"
      ) {
        this.$EventBus.$emit("getMyTableData");
      }
      // this.$store.commit("updateWaitFileList", waitLists);
    },

    // 重名
    check_dirname(dirName) {
      // file_lists 当前文件列表
      var idX = 1;
      var is = true;
      var newDirName = "";
      while (is) {
        newDirName = dirName + idX;
        is = this.is_dir_name(newDirName);
        idX++;
        if (idX == 999) {
          is = false;
        }
      }
      return newDirName;
    },

    is_dir_name(newDirName) {
      var is_dir_name = false;
      var file_lists = this.$store.state.tableData_my_drawings;
      file_lists.forEach((item) => {
        if (newDirName == item.name) {
          is_dir_name = true;
        }
      });
      return is_dir_name;
    },

    // 重名
    check_cloudfilename(fileName) {
      // file_lists 当前文件列表
      var idX = 1
      var is = true
      var newfileName = ''
      var fistName = utils.getFileName(fileName)
      var lastName = utils.getExtensiona(fileName)
      while (is) {
        newfileName = fistName + "(" + idX + ")" + lastName;
        is = this.is_cloudfile_name(newfileName);
        idX++;
        if (idX == 999) {
          is = false
        }
      }
      return newfileName
    },

    is_cloudfile_name(newfileName) {
      var is_file_name = false
      var files = this.$store.state.tableData_my_drawings
      files.forEach(item => {
      if (newfileName == item.name) {
        is_file_name = true
      }
      });
      return is_file_name
    },

  },
};
</script>

<style scoped lang="less">
.el-progress {
  width: 198px;
  margin: auto;
}

/deep/ .el-progress .el-progress-bar__outer {
  background: #ced0d1;
}

.line2 {
  height: 100%;
  position: absolute;
  right: 0;
  width: 1px;
  background: #e7e7e7;
  top: 0;
}

.item-type-1 {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}

#upload_all_btn {
  user-select: none;
}

#upload_all_btn:hover {
  background-color: #3669c0;
}

.upload-btn {
  width: 198px;
  height: 34px;
  line-height: 34px;
  background-color: #4383f0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
  margin-top: 20px;
  user-select: none;
}

.upload-btn-img {
  line-height: 1;
}

.upload-btn-img img {
  width: 18px;
  height: 18px;
}

.upload-btn-word {
  font-size: 14px;
  color: #fff;
  margin-left: 11px;
}

.item-word {
  margin-left: 12px;
}

#create_newdwg_btn {
  background-color: #ffffff;
  border-radius: 2px;
  border: solid 1px #4383f0;
}

#create_newdwg_btn:hover {
  background-color: #f0f4fc;
}

#create_newdwg_btn .upload-btn-word {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #4383f0;
}

.el-menu-item {
  width: 198px;
  height: 34px;
  line-height: 34px;
  margin: 0 auto;
}

/deep/ .el-menu-item:hover {
  background-color: #f0f4fc !important;
}

.item-type-2 {
  width: 100%;
}

.item {
  width: 198px;
  height: 34px;
  line-height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-left: 16px;
  font-size: 14px;
  /* color: #6c748a; */
  color: #333;
  border-radius: 4px;
}

.item:hover {
  background-color: #f0f4fc;
}

.item-empty {
  height: 30px;
}

.item-active {
  background-color: #f0f4fc;
  color: #4383f0;
}

.item-img {
  margin-left: 18px;
  margin-top: -3px;
  line-height: 1;
}

.item-img-right {
  line-height: 1;
}

.item-word {
  margin-left: 12px;
}

.my-team {
  margin-top: 15px;
}

#cloud_progress {
  width: 198px;
  height: 6px;
  background-color: #ced0d1;
  border-radius: 4px;
  margin-left: 16px;
  /* margin-top: 188px; */
}

#cur_cloud_progress {
  width: 0;
  height: 6px;
  background-color: #4383f0;
  border-radius: 3px;
}

.cloud-size {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  margin-top: 5px;
}

.cloud-size-word {
  width: 198px;
  height: 24px;
  line-height: 24px;
  margin-left: 16px;
  font-size: 14px;
  color: #666;
}
.up_all_btn_box {
  width: 198px;
  height: 100px;
  background-color: #ffffff;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  border: solid 1px #d3d3d3;
  user-select: none;
  /* opacity: 0; */
  position: absolute;
  top: -15px;
  left: -185px;
}

.up_all_btn_flex {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.up_all_btn_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 64px;
  height: 80px;
  cursor: pointer;
}

.up_all_btn_item:hover {
  background-color: #f0f4fc;
}

.up_all_btn_item span {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #333333;
  display: block;
}

.up_all_btn_item .upload-demo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.up_all_btn_item_line {
  width: 1px;
  height: 61px;
  background-color: #d3d3d3;
}

#upload_all_btn {
  user-select: none;
}

#upload_all_btn:hover {
  background-color: #3669c0;
}

#create_newdwg_btn {
  background-color: #ffffff;
  border-radius: 2px;
  border: solid 1px #4383f0;
}

#create_newdwg_btn:hover {
  background-color: #f0f4fc;
}

#create_newdwg_btn .upload-btn-word {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #4383f0;
}

.line3 {
  width: 198px;
  margin-left: 16px;
  height: 1px;
  background-color: #e7e7e7;
  margin-top: 15px;
  margin-bottom: 15px;
}

.cloud-guanggao {
  width: 198px;
  height: 129px;
  margin: auto;
  position: relative;
  background-color: #f9fafd;
  cursor: pointer;
  /* box-shadow: 0px 1px 3px 0px rgb(5 66 166 / 40%) */
}

.cloud-guanggao-bg {
  width: 126px;
  height: 81px;
  background-image: url("@/assets/images/cloud/add_team_gg.png");
  background-size: cover;
  position: absolute;
  bottom: 0;
  right: 14px;
}

.cloud-guanggao-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.cloud-guanggao-top-left {
  font-size: 14px;
  color: #333333;
}

.cloud-guanggao-top-right {
  width: 18px;
  height: 18px;
  background-image: url("@/assets/images/cloud/add_team_gg_x.png");
  background-size: cover;
  cursor: pointer;
}

.cloud-guanggao-btn {
  width: 66px;
  height: 24px;
  line-height: 24px;
  background-color: #e3eaff;
  border-radius: 2px;
  color: #4383f0;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
}

.cloud-guanggao-big {
  width: 994px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%);
  z-index: 55;
  background-color: #fff;
  border-radius: 4px;
  display: none;
}

.cloud-guanggao-big-top-x {
  width: 22px;
  height: 22px;
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.cloud-guanggao-big-top-x img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -20px;
  right: -8px;
}

.cloud-guanggao-big-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 10px 30px;
}

.cloud-guanggao-big-top-header {
  font-size: 20px;
  color: #000;
  width: 100%;
  text-align: center;
}

.cloud-guanggao-big-main {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 40px 20px;
}

.cloud-guanggao-big-main-left {
  width: 60%;
  background-size: cover;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cloud-guanggao-big-main-left img {
  width: 458px;
  height: 340px;
}

.cloud-guanggao-big-main-right {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 40px;
}

.cloud-guanggao-big-main-right-top {
  font-size: 16px;
  color: #000;
}

.cloud-guanggao-big-main-right-main {
  font-size: 14px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 30px;
}

.cloud-guanggao-big-main-right-btn {
  font-size: 14px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  /* background-image: linear-gradient(129deg, #4383f0 0%, #50acf9 100%); */
  background-color: #4383f0;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.con-items {
  height: calc(100% - 280px);
  overflow: auto;
  min-height: 350px;
}
.con-left {
  width: 230px;
  min-width: 230px;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

//上传图纸按钮
.upload-demo1 {
  width: 100%;
  height: 34px;
}

/deep/ .upload-demo1 .el-upload {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
